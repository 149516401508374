import React, { useState } from "react"
import Dropdown from "../Ui/Dropdown"
import {Block, Note} from "@root/pages/sandbox"
import { SectionTitle } from "../Ui"
import { PickerOverlay } from "."
import { buildPickerEntries } from "@root/src/views/MatchdayView"
import { MOCK_CONFIG } from "@root/src/helper/mock"
import { Player } from "@root/types"
import { MockAppContext } from "@root/src/context"
import { INIT_STATE } from "@root/src/state"
import _ from "lodash"
import { WithFixedCloseIcon } from "../FixedCloseIcon"

const Sandbox = () => {
  const [pickerOpen, setPickerOpen] = useState(false)
  const [lastResult, setLastResult] = useState("")
	return (
    <WithFixedCloseIcon defaultClose={() => console.log("default close")}>
      <SectionTitle>Picker</SectionTitle>
			<Block>
				<Note>
          <a onClick={() => setPickerOpen(true)}>Open picker</a>{` last result: ${lastResult}`}
        </Note>
        <MockAppContext state={{...INIT_STATE, config: {...MOCK_CONFIG, initialized: true, requesting: false} as any}}>
          {pickerOpen &&
            <PickerOverlay
              bet={{
                config: {type: "goal_or_assist"},
                id: 999999999,              
              }}
              teams={MOCK_CONFIG.teams}
              entries={buildPickerEntries(
                {config: {type: "goal_or_assist"}, id: 999999999},
                [
                  {...MOCK_CONFIG.players[0], stats: {assists: 10, goals: 5}} as Player,
                  ..._.slice(MOCK_CONFIG.players as Player[], 1)
                ],
                // MOCK_CONFIG.players as Player[],
                [MOCK_CONFIG.players[1].id, MOCK_CONFIG.players[5].id]
              )}
              doClose={() => {setLastResult("closed"); setPickerOpen(false)}}
              doPick={(p) => {setLastResult(p.nickname || p.last_name || p.id); setPickerOpen(false)}} />}
        </MockAppContext>
			</Block>
		</WithFixedCloseIcon>
	)
}

export default Sandbox
