import React, { useEffect, useState } from "react"

import _ from "lodash"
import { MockAppContext } from "@root/src/context"
import DisplayErrorOverlay from "../DisplayErrorOverlay"
import { INIT_STATE } from "@root/src/state"
import { Block, Note, SectionTitle } from "@root/pages/sandbox"
import Popup from "."
import I18n from "i18n-js"

const Sandbox:React.FC = () => {
  const [showPopup, setShowPopup] = useState(false)

  return <>
    <SectionTitle>Popup</SectionTitle>
    <Note>
      <ul>
        <li><a onClick={() => setShowPopup(true)}>Show popup</a></li>
      </ul>
    </Note>
    <Block>
      {showPopup &&
        <Popup onClose={() => setShowPopup(false)} title="This is a popup!" closeLabel="Close">
          {I18n.t("tutorial.info")}
        </Popup>}
    </Block>
  </>
}

export default ["ui", Sandbox]
