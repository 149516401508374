import React, { useState } from "react"
import Dropdown from "../Ui/Dropdown"
import {Block, Note} from "@root/pages/sandbox"
import { SectionTitle } from "../Ui"
import { MockAppContext } from "@root/src/context"
import { INIT_STATE } from "@root/src/state"
import Header from "."

const Sandbox = () => {
	return <>
    <SectionTitle>Header</SectionTitle>
    <Note>No loaded user</Note>
    <Block>
      <MockAppContext state={{...INIT_STATE}}>
        <Header />
      </MockAppContext>
    </Block>
    <Note>User, but still needs to complete profile (still no username)</Note>
    <Block>
      <MockAppContext state={{...INIT_STATE,
        me: {
          ...INIT_STATE.me,
          initialized: true,
          needsToCompleteProfile: true,
          user: {id: 9999999, label: "Sonic"}
        }}}>
        <Header />
      </MockAppContext>
    </Block>
    <Note>User, completes profile but no stats (with username)</Note>
    <Block>
      <MockAppContext state={{...INIT_STATE,
        me: {
          ...INIT_STATE.me,
          initialized: true,
          user: {id: 9999999, label: "Sonic"}
        }}}>
        <Header />
      </MockAppContext>
    </Block>
    <Note>User + stats</Note>
    <Block>
      <MockAppContext state={{...INIT_STATE,
        me: {
          ...INIT_STATE.me,
          initialized: true,
          user: {id: 9999999, label: "Sonic"},
          stats: {
            season_points: { val: 99, trend: "down"},
            season_rank: { val: 2, trend: "up"},
            weekly_rank: { val: 1234, trend: "same"},
          }
        }}}>
        <Header />
      </MockAppContext>
    </Block>
  </>
}

export default Sandbox
