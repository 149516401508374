import { Block, Note } from "@root/pages/sandbox"
import React from "react"
import NavBar from "./LeaderBoard_navBar"
import { Tab } from "@root/types"

const TABS: Tab[] = ["picks" , "leaderboard"]

const NavBarMock = () => {
  return (
    <>
      <Block>
        <Note>Tab bar</Note>
        <NavBar tabs={TABS} onSelectTab={(tab: "leaderboard" | "picks" ) => console.log("clicked", tab)} activeTab="leaderboard"/>
      </Block>
      <Block>
        <Note>Tab bar no tabs</Note>
        <NavBar tabs={[]} onSelectTab={(tab: "leaderboard" | "picks" ) => console.log("clicked", tab)} activeTab="picks"/>
      </Block>
    </>
  
  )
}

export default ["NavBar", NavBarMock]
