import React, { useEffect, useState } from "react"

import _ from "lodash"
import { MockAppContext } from "@root/src/context"
import { Block, Note, SectionTitle } from "@root/pages/sandbox"
import { INIT_STATE } from "@root/src/state"
import StoryOverlay from "."
import { MOCK_CONFIG } from "@root/src/helper/mock"
import { Player } from "@root/types"
import { WithFixedCloseIcon } from "../FixedCloseIcon"

const Sandbox:React.FC = () => {
  const [show, setShow] = useState<"other" | "expert" | "self" | "other_empty">()
  const userId = {
    "self": 99999999,
    "other": 99999998,
    "other_empty": 99999998,
    "expert": 99999997,
  }[show] || 0
  return <WithFixedCloseIcon defaultClose={() => console.log("default close")}>
    <Note>
      <ul>
        <li><a onClick={() => setShow("other")}>Show <strong>other (buddy)</strong></a></li>
        <li><a onClick={() => setShow("other_empty")}>Show <strong>other (buddy) censored</strong></a></li>
        <li><a onClick={() => setShow("self")}>Show <strong>self</strong></a></li>
        <li><a onClick={() => setShow("expert")}>Show <strong>expert</strong></a></li>
      </ul>
      <p>me/other/expert should have different bg, buddy should have remove-button</p>
    </Note>
    <Block>
      <MockAppContext state={{...INIT_STATE, config: MOCK_CONFIG as any,
        me: {
          initialized: true,
          user: {id: 99999999, label: "Meeeeeeee"},
        },
        buddies: [{user: {id: 99999998, label: "Buddy"}}],
        storyView: {
          show: true,
          userId: userId,
          config: {
            story: {
              matchday_name: "17",
              matchday_id: "9999999999",
              matchdays: [
                {name: "17", id: "9999999999"},
                {name: "16", id: "9999999998"},
                {name: "15", id: "9999999997"},
                {name: "14", id: "9999999996"},
              ],
              user: show === "self"
                ? {id: userId, label: "Meeeeeeee"}
                : {id: userId, label: show === "expert" ? "Manuel Neuer" : "Dr. Eggman", is_expert: show === "expert"},
              countdown_still_open: show === "other_empty",
              picks: show === "other_empty"
                ? [{}, {}, {}, {}, {}]
                : [
                  {bet_config: {type: "goal_or_assist"}, correct: true, scored: true, points: 30, player: MOCK_CONFIG.players[1] as Player},
                  {bet_config: {type: "goal_or_assist"}, correct: true, scored: true, points: 30},
                  {bet_config: {type: "goal_or_assist"}, scored: false, points: 10},
                  {bet_config: {type: "goal_or_assist"}, correct: true, scored: true, points: 30},
                  {bet_config: {type: "shots_held"}, correct: true, scored: true, points: 30}
                ]
            }
          }
      }}}>
        {show &&
          <StoryOverlay doClose={() => setShow(undefined)} />}
      </MockAppContext>
    </Block>
  </WithFixedCloseIcon>
}

export default Sandbox
