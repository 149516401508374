import React from "react"

import { PageTitle, SectionTitle, Block, Note } from '@root/pages/sandbox'
import _ from "lodash"
import LandingView from "./LandingView"
import { MockAppContext } from "../context"
import { INIT_STATE } from "../state"

const Sandbox:React.FC = () => {
  return <>
    <SectionTitle>Logged in</SectionTitle>
    <Block>
      <MockAppContext state={{...INIT_STATE, me: {initialized: true, user: {id: 99999999, label: "Sonic"}}}}>
        <LandingView />
      </MockAppContext>
    </Block>
    <SectionTitle>Countdown till closed</SectionTitle>
    <Block>
      <MockAppContext state={{...INIT_STATE, me: {initialized: true, user: {id: 99999999, label: "Sonic"}}, landingView: {countdown: {sec: 999}}}}>
        <LandingView />
      </MockAppContext>
    </Block>
    <SectionTitle>Countdown to next matchday</SectionTitle>
    <Block>
      <MockAppContext state={{...INIT_STATE, me: {initialized: true, user: {id: 99999999, label: "Sonic"}}, landingView: {next_matchday_opens_in_sec: {sec: 999}}}}>
        <LandingView />
      </MockAppContext>
    </Block>
    <SectionTitle>Not logged in</SectionTitle>
    <Block>
      <MockAppContext state={INIT_STATE}>
        <LandingView />
      </MockAppContext>
    </Block>
  </>
}

export default ["#/", Sandbox]
