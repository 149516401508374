import React, { PropsWithChildren } from "react"
import { User } from "@root/types"

interface AvatarProps {
  user: User,
  imageUrl?: string
  onAvatarClick: (user: User) => void
}

export const Avatar: React.FC<AvatarProps> = ({ user, imageUrl, onAvatarClick }) => {
  if (!user) {
    return <></>
  }
  const renderContent = () => {
    if (imageUrl) {
      return <img src={imageUrl} alt={user.label} className="neo__avatar__image" loading="lazy"/>
    } else {
      const firstLetter = user.label.charAt(0).toUpperCase()
      return <span className="neo__avatar__letter">{firstLetter}</span>
    }
  }

  return <div className="neo__avatar__container" onClick={()=>onAvatarClick(user)}>{renderContent()}</div>
}
type AvatarWithHaloProps = AvatarProps & {
  isSeen: boolean
}

export const AvatarWithHalo: React.FC<AvatarWithHaloProps> = ({user, imageUrl, isSeen, onAvatarClick}) => {
  return (
    <div className="neo__avatarWithHalo__container">
      <Halo isSeen={isSeen}>
        <Avatar user={user} imageUrl={imageUrl} onAvatarClick={onAvatarClick}/>
      </Halo>
    </div>
  )
}

export const Halo: React.FC<{isSeen: boolean} & PropsWithChildren> = ({isSeen, children}) => {
  return (
    <>
      <div className={`neo__avatar__halo ${isSeen ? "neo__avatar__halo--seen" : ""}`}>{children}</div>
    </>
  )
}

export const AvatarAdd: React.FC<{onAvatarClick: () => void}> = ({ onAvatarClick }) => {
  return (
    <div className="neo__avatar__container" onClick={()=>onAvatarClick()}>
      <span className="neo__avatar__letter">+</span>
    </div>
  )
}