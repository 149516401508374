import Rails from "@rails/ujs"
import React from "react"
import {createRoot} from "react-dom/client"

import "./stylesheets/application.scss"

(() => {
    let importAll = function (r: any) {
        r.keys().forEach(r)
    }

    importAll(require.context("./images", true, /.(png|jpg|svg)$/))


    console.log("First in pack: application")
    Rails.start()

    const pageContext = require.context("./pages", false)
    importAll(pageContext)

    pageContext.keys().forEach((key) => {
        const match = key.match(/\.\/(\w+)\./)
        if (!match) {
            return
        }
        const container = document.getElementById(`page-${match[1]}`)
        if (!container) {
            return
        }
        const root = createRoot(container)
        const Page = pageContext(key).default
        const init = JSON.parse(container.getAttribute("data-init") || "{}")
        document.addEventListener("DOMContentLoaded", () => {
            root.render(<Page init={init}/>)
        })
    })

})();
