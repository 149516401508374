import React, { useEffect, useState } from "react"

import { PageTitle, SectionTitle, Block, Note } from '@root/pages/sandbox'
import _ from "lodash"
import { MockAppContext, useAppContext } from "../context"
import { INIT_STATE } from "../state"
import MatchdayView from "./MatchdayView"
import { Row } from "../components/Ui"
import MatchdayCountdown from "../components/MatchdayCountdown"
import { useMatchdayState } from "../helper/matchdayState"
import { Bet, Player } from "@root/types"
import { MOCK_CONFIG } from "../helper/mock"
import { WithFixedCloseIcon } from "../components/FixedCloseIcon"

const MatchdayStateSandbox: React.FC = () => {
  const {state} = useAppContext()
  const matchday = state.matchdayView.config.matchday
  const matchdayState = useMatchdayState(matchday)

  return <>
    <Row>State: {matchdayState.state}</Row>
    <Row>
      <MatchdayCountdown {...matchdayState} />
    </Row>
  </>
}

const BETS = (withResults?: boolean): Bet[] => {
  return [
    { config: {type: "clean_sheet"}, id: 99999999, pick: MOCK_CONFIG.players[2] as Player, result: withResults ? {correct: false, points: 20} : undefined},
    { config: {type: "duels_won", arg: 3}, id: 99999998, pick: MOCK_CONFIG.players[3] as Player, result: withResults ? {correct: true, points: 20} : undefined},
    { config: {type: "goal_or_assist"}, id: 99999997, pick: MOCK_CONFIG.players[4] as Player, result: withResults ? {correct: true, points: 20} : undefined},
    { config: {type: "shots_held", arg: 3}, id: 99999996, result: withResults ? {correct: false, points: 20} : undefined},
    { config: {type: "winning_team"}, id: 99999995, result: withResults ? {correct: false, points: 20} : undefined},
  ]
}

const Sandbox:React.FC = () => {
  const [showTutorial, setShowTutorial] = useState(false)
  
  useEffect(() => {
    let timeout: number
    if (showTutorial) {
      timeout = window.setTimeout(() => setShowTutorial(false), 3000)
    }
    return () => { // cleanup
      if (_.isNumber(timeout)) { window.clearTimeout(timeout)}
    }
  }, [showTutorial])


  return <WithFixedCloseIcon defaultClose={() => console.log("default close")}>
    <SectionTitle>Loading</SectionTitle>
    <Block>
      <MockAppContext state={{...INIT_STATE}}>
        <MatchdayView />
      </MockAppContext>
    </Block>
    <SectionTitle>Loaded, open</SectionTitle>
    <Note>
      <a onClick={() => setShowTutorial(true)}>Show tutorial for a few sec</a>
    </Note>
    <Block>
      <MockAppContext state={{...INIT_STATE, config: MOCK_CONFIG as any,
        matchdayView: {
          alreadyDisplayedTutorial: !showTutorial,
          config: {
            matchday: {
              id: 99999999,
              state: "open",
              countdown_sec: 99999,
              bets: BETS(false),
              subtitle: "May 1st - 7th",
              title: "33"
            },
            show_tutorial: true
          }
        }
      }}>
        <MatchdayView />
      </MockAppContext>
    </Block>
    <SectionTitle>In progress</SectionTitle>
    <Block>
      <MockAppContext state={{...INIT_STATE, config: MOCK_CONFIG as any,
        matchdayView: {
          config: {
            matchday: {
              id: 99999999,
              state: "in_progress",
              bets: BETS(true),
              subtitle: "May 1st - 7th",
              title: "33"
            }
          }
        }
      }}>
        <MatchdayView />
      </MockAppContext>
    </Block>
    <SectionTitle>Scored + link to next matchday</SectionTitle>
    <Block>
      <MockAppContext state={{...INIT_STATE, config: MOCK_CONFIG as any,
        matchdayView: {
          config: {
            is_next_matchday_active: true,
            matchday: {
              id: 99999999,
              state: "scored",
              countdown_sec: 99999,
              bets: BETS(true),
              subtitle: "May 1st - 7th",
              title: "33"
            }
          }
        }
      }}>
        <MatchdayView />
      </MockAppContext>
    </Block>
    <SectionTitle>Scored but next matchday not yet open (show countdown)</SectionTitle>
    <Block>
      <MockAppContext state={{...INIT_STATE, config: MOCK_CONFIG as any,
        matchdayView: {
          config: {
            next_matchday_opens_in_sec: 999,
            matchday: {
              id: 99999999,
              state: "scored",
              countdown_sec: 99999,
              bets: BETS(true),
              subtitle: "May 1st - 7th",
              title: "33"
            }
          }
        }
      }}>
        <MatchdayView />
      </MockAppContext>
    </Block>
    <SectionTitle>Scored + NO link to next matchday</SectionTitle>
    <Block>
      <MockAppContext state={{...INIT_STATE, config: MOCK_CONFIG as any,
        matchdayView: {
          config: {
            is_next_matchday_active: false,
            matchday: {
              id: 99999999,
              state: "scored",
              countdown_sec: 99999,
              bets: BETS(true),
              subtitle: "May 1st - 7th",
              title: "33"
            }
          }
        }
      }}>
        <MatchdayView />
      </MockAppContext>
    </Block>
    <SectionTitle>State</SectionTitle>
    <Block>
      <Note>Should toggle to "in_progress" when countdown over</Note>
      <MockAppContext state={{...INIT_STATE,
        matchdayView: {
          config: {
            matchday: {
              id: 99999999,
              state: "open",
              countdown_sec: 10,
              bets: [],
              subtitle: "May 1st - 7th",
              title: "33"
            }
          }
        }
      }}>
        <MatchdayStateSandbox />
      </MockAppContext>
    </Block>
  </WithFixedCloseIcon>
}

export default ["#/matchday", Sandbox]
