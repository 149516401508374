import React, { useState } from "react"
import Dropdown from "../Ui/Dropdown"
import {Block, Note} from "@root/pages/sandbox"
import { SectionTitle } from "../Ui"
import { buildPickerEntries } from "@root/src/views/MatchdayView"
import { MOCK_CONFIG } from "@root/src/helper/mock"
import { Player } from "@root/types"
import { MockAppContext } from "@root/src/context"
import { INIT_STATE } from "@root/src/state"
import { BuddySearchOverlay } from "."
import { AVATAR_ICONS } from "../Buddy"
import { WithFixedCloseIcon } from "../FixedCloseIcon"

const Sandbox = () => {
  const [buddySearchOpen, setBuddySearchOpen] = useState<"none" | "many">()
	return (
		<WithFixedCloseIcon defaultClose={() => console.log("default close")}>
      <SectionTitle>Picker</SectionTitle>
			<Block>
				<Note>
          <ul>
            <li><a onClick={() => setBuddySearchOpen("many")}>Open buddy search (many)</a></li>
            <li><a onClick={() => setBuddySearchOpen("none")}>Open buddy search (none)</a></li>
            <li>Samus and Peach are already buddies, should have checkmark instead of button</li>
          </ul>
        </Note>
        <MockAppContext state={{...INIT_STATE,
          buddies: [
            {user: {id: 99999997}},
            {user: {id: 99999995}},
          ],
          buddiesSearchView: {
            show: true,
            config: {
              page: 1,
              total_pages: buddySearchOpen === "none" ? 1 : 10,
              entries: buddySearchOpen === "none" ? [] : [
                {id: 99999999, image: {type: "icon", arg: AVATAR_ICONS[0]}, label: "Sonic"},
                {id: 99999998, image: {type: "icon", arg: AVATAR_ICONS[1]}, label: "Knuckles"},
                {id: 99999997, image: {type: "icon", arg: AVATAR_ICONS[2]}, label: "Samus Aran"},
                {id: 99999996, image: {type: "icon", arg: AVATAR_ICONS[3]}, label: "Mario Mario"},
                {id: 99999995, image: {type: "icon", arg: AVATAR_ICONS[4]}, label: "Peach Princess"},
                {id: 99999994, image: {type: "icon", arg: AVATAR_ICONS[5]}, label: "Sonic"},
                {id: 99999993, image: {type: "icon", arg: AVATAR_ICONS[6]}, label: "Sonic"},
                {id: 99999992, image: {type: "icon", arg: AVATAR_ICONS[2]}, label: "Sonic"},
                {id: 99999991, image: {type: "icon", arg: AVATAR_ICONS[3]}, label: "Sonic"},
                {id: 99999990, image: {type: "icon", arg: AVATAR_ICONS[1]}, label: "Sonic"},
              ]
            }
          }}}>
          {buddySearchOpen &&
            <BuddySearchOverlay doClose={() => setBuddySearchOpen(undefined)} />}
        </MockAppContext>
			</Block>
		</WithFixedCloseIcon>
	)
}

export default Sandbox
