export const MOCK_CONFIG = {
	"player_image_path": "https://assets.bundesliga.com/player/%{id}-dfl-sea-0001k7.png?fit=200,200",
	"players": [
		{
			"id": "438",
			"external_uid": "dfl-obj-j01glo-dfl-clu-00000v",
			"first_name": "Brenden Russell",
			"last_name": "Aaronson",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "88",
			"external_uid": "dfl-obj-j01sb3-dfl-clu-00000f",
			"first_name": "Paxten Reid",
			"last_name": "Aaronson",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "573",
			"external_uid": "dfl-obj-002gda-dfl-clu-00000f",
			"first_name": "Ragnar Prince Friedel",
			"last_name": "Ache",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "295",
			"external_uid": "dfl-obj-j01fo8-dfl-clu-00000a",
			"first_name": "Junior Chukwubuike",
			"last_name": "Adamu",
			"team_code": "SCF",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "124",
			"external_uid": "dfl-obj-0027pp-dfl-clu-000008",
			"first_name": "Sargis",
			"last_name": "Adamyan",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "236",
			"external_uid": "dfl-obj-j01e9x-dfl-clu-000007",
			"first_name": "Karim-David",
			"last_name": "Adeyemi",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "173",
			"external_uid": "dfl-obj-j01kdn-dfl-clu-00000b",
			"first_name": "Amine",
			"last_name": "Adli",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "47",
			"external_uid": "dfl-obj-002fzo-dfl-clu-00000e",
			"first_name": "Felix",
			"last_name": "Agu",
			"team_code": "SVW",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "655",
			"external_uid": "dfl-obj-002fwf-dfl-clu-00000d",
			"first_name": "Antonis",
			"last_name": "Aidonis",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "345",
			"external_uid": "dfl-obj-j01cyh-dfl-clu-000006",
			"first_name": "Ludovic",
			"last_name": "Ajorque",
			"team_code": "M05",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "538",
			"external_uid": "dfl-obj-j01gsh-dfl-clu-00000f",
			"first_name": "Ali",
			"last_name": "Akman",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "648",
			"external_uid": "dfl-obj-0002jk-dfl-clu-000003",
			"first_name": "Josuha Jérémy",
			"last_name": "Akoi Fara Guilavogui",
			"team_code": "WOB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "67",
			"external_uid": "dfl-obj-0028gg-dfl-clu-00000f",
			"first_name": "Lucas Nicolás",
			"last_name": "Alario",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "143",
			"external_uid": "dfl-obj-002gjq-dfl-clu-00000f",
			"first_name": "Faride",
			"last_name": "Alidou",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "401",
			"external_uid": "dfl-obj-002g1v-dfl-clu-000010",
			"first_name": "Iago",
			"last_name": "Amaral Borduchi",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "374",
			"external_uid": "dfl-obj-j01pzv-dfl-clu-000003",
			"first_name": "Lukáš",
			"last_name": "Ambros",
			"team_code": "WOB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "158",
			"external_uid": "dfl-obj-0002li-dfl-clu-00000b",
			"first_name": "Nadiem",
			"last_name": "Amiri",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "638",
			"external_uid": "dfl-obj-0028fg-dfl-clu-000008",
			"first_name": "Martin Sebastian",
			"last_name": "Andersson",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "155",
			"external_uid": "dfl-obj-0001ew-dfl-clu-00000b",
			"first_name": "Robert",
			"last_name": "Andrich",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "97",
			"external_uid": "dfl-obj-00264t-dfl-clu-00000d",
			"first_name": "Waldemar",
			"last_name": "Anton",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "258",
			"external_uid": "dfl-obj-0028nb-dfl-clu-00000s",
			"first_name": "Christopher",
			"last_name": "Antwi-Adjei",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "167",
			"external_uid": "dfl-obj-002gfo-dfl-clu-00000b",
			"first_name": "Ayman",
			"last_name": "Aourir",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "611",
			"external_uid": "dfl-obj-0026ok-dfl-clu-00000b",
			"first_name": "Charles Mariano",
			"last_name": "Aránguiz Sandoval",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "353",
			"external_uid": "dfl-obj-0000y4-dfl-clu-000003",
			"first_name": "Maximilian",
			"last_name": "Arnold",
			"team_code": "WOB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "255",
			"external_uid": "dfl-obj-0027fg-dfl-clu-00000s",
			"first_name": "Takuma",
			"last_name": "Asano",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "470",
			"external_uid": "dfl-obj-002gn3-dfl-clu-000002",
			"first_name": "Fisnik",
			"last_name": "Asllani",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "287",
			"external_uid": "dfl-obj-002g6c-dfl-clu-00000a",
			"first_name": "Noah",
			"last_name": "Atubolu",
			"team_code": "SCF",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "685",
			"external_uid": "dfl-obj-002g57-dfl-clu-00000b",
			"first_name": "Ayman",
			"last_name": "Azhil",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "157",
			"external_uid": "dfl-obj-j013o2-dfl-clu-00000b",
			"first_name": "Sardar",
			"last_name": "Azmoun",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "660",
			"external_uid": "dfl-obj-j01k9i-dfl-clu-000017",
			"first_name": "Sanoussy",
			"last_name": "Ba",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "599",
			"external_uid": "dfl-obj-j01k9l-dfl-clu-00000e",
			"first_name": "Mio",
			"last_name": "Backhaus",
			"team_code": "SVW",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "306",
			"external_uid": "dfl-obj-00265u-dfl-clu-000016",
			"first_name": "Matthias",
			"last_name": "Bader",
			"team_code": "SVD",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "148",
			"external_uid": "dfl-obj-j01q84-dfl-clu-000008",
			"first_name": "Elias Geoffrey",
			"last_name": "Bakatukanda",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "594",
			"external_uid": "dfl-obj-j01b8r-dfl-clu-00000b",
			"first_name": "Mitchel",
			"last_name": "Bakker",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "235",
			"external_uid": "dfl-obj-002gft-dfl-clu-000007",
			"first_name": "Samuel",
			"last_name": "Bamba",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "335",
			"external_uid": "dfl-obj-0027ic-dfl-clu-000006",
			"first_name": "Aymane",
			"last_name": "Barkok",
			"team_code": "M05",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "339",
			"external_uid": "dfl-obj-0028ss-dfl-clu-000006",
			"first_name": "Leandro",
			"last_name": "Barreiro Martins",
			"team_code": "M05",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "366",
			"external_uid": "dfl-obj-j01eag-dfl-clu-000003",
			"first_name": "Tiago",
			"last_name": "Barreiros de Melo Tomás",
			"team_code": "WOB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "102",
			"external_uid": "dfl-obj-j01955-dfl-clu-00000d",
			"first_name": "Gil",
			"last_name": "Bastiao Dias",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "328",
			"external_uid": "dfl-obj-0000qs-dfl-clu-000006",
			"first_name": "Daniel",
			"last_name": "Batz",
			"team_code": "M05",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "399",
			"external_uid": "dfl-obj-0028rz-dfl-clu-000010",
			"first_name": "Maximilian",
			"last_name": "Bauer",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "444",
			"external_uid": "dfl-obj-00001e-dfl-clu-000002",
			"first_name": "Oliver",
			"last_name": "Baumann",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "628",
			"external_uid": "dfl-obj-00028k-dfl-clu-00000v",
			"first_name": "Timo",
			"last_name": "Baumgartl",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "649",
			"external_uid": "dfl-obj-0000r6-dfl-clu-000010",
			"first_name": "Julian",
			"last_name": "Baumgartlinger",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "517",
			"external_uid": "dfl-obj-0028kn-dfl-clu-000017",
			"first_name": "Christoph",
			"last_name": "Baumgartner",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "293",
			"external_uid": "dfl-obj-j01ea5-dfl-clu-00000a",
			"first_name": "Mika Joel",
			"last_name": "Baur",
			"team_code": "SCF",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "89",
			"external_uid": "dfl-obj-j01ug2-dfl-clu-00000f",
			"first_name": "Davis Carlos",
			"last_name": "Bautista Medina",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "669",
			"external_uid": "dfl-obj-j01k2s-dfl-clu-00000d",
			"first_name": "Luca Juliano",
			"last_name": "Bazzoli",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "451",
			"external_uid": "dfl-obj-0001v0-dfl-clu-000002",
			"first_name": "Ihlas",
			"last_name": "Bebou",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "489",
			"external_uid": "dfl-obj-j01auu-dfl-clu-000018",
			"first_name": "Adrian",
			"last_name": "Beck",
			"team_code": "HDH",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "432",
			"external_uid": "dfl-obj-002g1y-dfl-clu-00000v",
			"first_name": "Sheraldo",
			"last_name": "Becker",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "462",
			"external_uid": "dfl-obj-0028rp-dfl-clu-000002",
			"first_name": "Finn Ole",
			"last_name": "Becker",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "422",
			"external_uid": "dfl-obj-0028ng-dfl-clu-00000v",
			"first_name": "Kevin",
			"last_name": "Behrens",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "310",
			"external_uid": "dfl-obj-0028nh-dfl-clu-000016",
			"first_name": "Morten Jens",
			"last_name": "Behrens",
			"team_code": "SVD",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "466",
			"external_uid": "dfl-obj-002g7p-dfl-clu-000002",
			"first_name": "Maximilian",
			"last_name": "Beier",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "410",
			"external_uid": "dfl-obj-j01seg-dfl-clu-000010",
			"first_name": "Dion Drena",
			"last_name": "Beljo",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "326",
			"external_uid": "dfl-obj-00001r-dfl-clu-000006",
			"first_name": "Stefan",
			"last_name": "Bell",
			"team_code": "M05",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "640",
			"external_uid": "dfl-obj-0000mw-dfl-clu-00000b",
			"first_name": "Karim",
			"last_name": "Bellarabi",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "229",
			"external_uid": "dfl-obj-002g5s-dfl-clu-000007",
			"first_name": "Ramy",
			"last_name": "Bensebaini",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "664",
			"external_uid": "dfl-obj-j01d0t-dfl-clu-00000e",
			"first_name": "Tom-Kasper",
			"last_name": "Berger",
			"team_code": "SVW",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "389",
			"external_uid": "dfl-obj-0028nk-dfl-clu-000010",
			"first_name": "Mërgim",
			"last_name": "Berisha",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "254",
			"external_uid": "dfl-obj-j01742-dfl-clu-00000s",
			"first_name": "Matúš",
			"last_name": "Bero",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "486",
			"external_uid": "dfl-obj-0028b6-dfl-clu-000018",
			"first_name": "Jan-Niklas",
			"last_name": "Beste",
			"team_code": "HDH",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "559",
			"external_uid": "dfl-obj-j01i6a-dfl-clu-00000d",
			"first_name": "Ömer Faruk",
			"last_name": "Beyaz",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "580",
			"external_uid": "dfl-obj-0000lm-dfl-clu-000002",
			"first_name": "Ermin",
			"last_name": "Bičakčić",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "674",
			"external_uid": "dfl-obj-j01mk1-dfl-clu-00000f",
			"first_name": "Matteo",
			"last_name": "Bignetti",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "471",
			"external_uid": "dfl-obj-j01k2f-dfl-clu-000002",
			"first_name": "Tom",
			"last_name": "Bischof",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "525",
			"external_uid": "dfl-obj-j01k70-dfl-clu-000017",
			"first_name": "El Chadaille",
			"last_name": "Bitshiabu",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "505",
			"external_uid": "dfl-obj-0000nt-dfl-clu-000017",
			"first_name": "Janis Jonathan",
			"last_name": "Blaswich",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "607",
			"external_uid": "dfl-obj-j00use-dfl-clu-00000g",
			"first_name": "Daley",
			"last_name": "Blind",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "550",
			"external_uid": "dfl-obj-j01dsx-dfl-clu-000006",
			"first_name": "Ben Justus",
			"last_name": "Bobzien",
			"team_code": "M05",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "546",
			"external_uid": "dfl-obj-002g88-dfl-clu-000002",
			"first_name": "Melayro Chakewno Jalaino",
			"last_name": "Bogarde",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "176",
			"external_uid": "dfl-obj-j01tl9-dfl-clu-00000b",
			"first_name": "Victor Okoh",
			"last_name": "Boniface",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "207",
			"external_uid": "dfl-obj-j01nsk-dfl-clu-000004",
			"first_name": "Yvandro",
			"last_name": "Borges Sanches",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "363",
			"external_uid": "dfl-obj-002g53-dfl-clu-000003",
			"first_name": "Sebastiaan",
			"last_name": "Bornauw",
			"team_code": "WOB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "219",
			"external_uid": "dfl-obj-000280-dfl-clu-000007",
			"first_name": "Julian",
			"last_name": "Brandt",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "597",
			"external_uid": "dfl-obj-j01s1t-dfl-clu-000003",
			"first_name": "Manuel",
			"last_name": "Braun",
			"team_code": "WOB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "626",
			"external_uid": "dfl-obj-j01jmw-dfl-clu-00000b",
			"first_name": "Iker",
			"last_name": "Bravo Solanilla",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "92",
			"external_uid": "dfl-obj-0027q9-dfl-clu-00000d",
			"first_name": "Fabian",
			"last_name": "Bredlow",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "404",
			"external_uid": "dfl-obj-002gcf-dfl-clu-000010",
			"first_name": "Tim",
			"last_name": "Breithaupt",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "284",
			"external_uid": "dfl-obj-0028pw-dfl-clu-00000a",
			"first_name": "Maximilian",
			"last_name": "Breunig",
			"team_code": "SCF",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "448",
			"external_uid": "dfl-obj-0000rt-dfl-clu-000002",
			"first_name": "John Anthony",
			"last_name": "Brooks Jr",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "261",
			"external_uid": "dfl-obj-j01aqv-dfl-clu-00000s",
			"first_name": "Moritz",
			"last_name": "Broschinski",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "198",
			"external_uid": "dfl-obj-002gg3-dfl-clu-000004",
			"first_name": "Maximilian",
			"last_name": "Brüll",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "436",
			"external_uid": "dfl-obj-002gmy-dfl-clu-00000v",
			"first_name": "Mathis",
			"last_name": "Bruns",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "303",
			"external_uid": "dfl-obj-0002e6-dfl-clu-000016",
			"first_name": "Alexander",
			"last_name": "Brunst-Zöllner",
			"team_code": "SVD",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "570",
			"external_uid": "dfl-obj-0026q4-dfl-clu-000002",
			"first_name": "Jacob",
			"last_name": "Bruun Larsen",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "565",
			"external_uid": "dfl-obj-j01pfd-dfl-clu-00000e",
			"first_name": "Lee David",
			"last_name": "Buchanan",
			"team_code": "SVW",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "26",
			"external_uid": "dfl-obj-j01eb0-dfl-clu-00000g",
			"first_name": "Tarek",
			"last_name": "Buchmann",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "461",
			"external_uid": "dfl-obj-0028nr-dfl-clu-000002",
			"first_name": "Marius",
			"last_name": "Bülter",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "535",
			"external_uid": "dfl-obj-j01mrv-dfl-clu-000006",
			"first_name": "Delano Dwayne",
			"last_name": "Burgzorg",
			"team_code": "M05",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "338",
			"external_uid": "dfl-obj-002fu7-dfl-clu-000006",
			"first_name": "Jonathan Michael",
			"last_name": "Burkardt",
			"team_code": "M05",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "44",
			"external_uid": "dfl-obj-0027fw-dfl-clu-00000e",
			"first_name": "Oliver Jasen",
			"last_name": "Burke",
			"team_code": "SVW",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "480",
			"external_uid": "dfl-obj-0002jt-dfl-clu-000018",
			"first_name": "Marnon-Thomas",
			"last_name": "Busch",
			"team_code": "HDH",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "416",
			"external_uid": "dfl-obj-0026tr-dfl-clu-00000v",
			"first_name": "Jakob",
			"last_name": "Busk Jensen",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "79",
			"external_uid": "dfl-obj-j01fyn-dfl-clu-00000f",
			"first_name": "Aurélio Gabriel Ulineia",
			"last_name": "Buta",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "239",
			"external_uid": "dfl-obj-j01niu-dfl-clu-000007",
			"first_name": "Jamie Jermaine",
			"last_name": "Bynoe-Gittens",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "341",
			"external_uid": "dfl-obj-j01c5k-dfl-clu-000006",
			"first_name": "Anthony",
			"last_name": "Caci",
			"team_code": "M05",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "635",
			"external_uid": "dfl-obj-00000w-dfl-clu-000010",
			"first_name": "Daniel",
			"last_name": "Caligiuri",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "215",
			"external_uid": "dfl-obj-00012t-dfl-clu-000007",
			"first_name": "Emre",
			"last_name": "Can",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "392",
			"external_uid": "dfl-obj-j0187i-dfl-clu-000010",
			"first_name": "Irvin Charly Jose",
			"last_name": "Cardona",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "145",
			"external_uid": "dfl-obj-j01gtc-dfl-clu-000008",
			"first_name": "Rasmus",
			"last_name": "Carstensen",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "352",
			"external_uid": "dfl-obj-0000vy-dfl-clu-000003",
			"first_name": "Koen",
			"last_name": "Casteels",
			"team_code": "WOB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "604",
			"external_uid": "dfl-obj-j015s4-dfl-clu-00000g",
			"first_name": "Joao Pedro",
			"last_name": "Cavaco Cancelo",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "320",
			"external_uid": "dfl-obj-j01fcn-dfl-clu-000016",
			"first_name": "Nemanja",
			"last_name": "Čelić",
			"team_code": "SVD",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "355",
			"external_uid": "dfl-obj-j01589-dfl-clu-000003",
			"first_name": "Václav",
			"last_name": "Černý",
			"team_code": "WOB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "141",
			"external_uid": "dfl-obj-j01d1w-dfl-clu-000008",
			"first_name": "Julian Jeffrey Gaston",
			"last_name": "Chabot",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "61",
			"external_uid": "dfl-obj-0000pi-dfl-clu-00000f",
			"first_name": "Timothy",
			"last_name": "Chandler",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "653",
			"external_uid": "dfl-obj-j01gt5-dfl-clu-000002",
			"first_name": "Justin Isiah",
			"last_name": "Che",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "204",
			"external_uid": "dfl-obj-j01jjj-dfl-clu-000004",
			"first_name": "Fabio Cristian",
			"last_name": "Chiarodia",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "3",
			"external_uid": "dfl-obj-0000rp-dfl-clu-00000g",
			"first_name": "Eric Maxim",
			"last_name": "Choupo-Moting",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "150",
			"external_uid": "dfl-obj-j01w1e-dfl-clu-000008",
			"first_name": "Jacob Steen Vestergaard",
			"last_name": "Christensen",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "539",
			"external_uid": "dfl-obj-002gff-dfl-clu-00000d",
			"first_name": "Alkhaly Momo",
			"last_name": "Cissé",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "524",
			"external_uid": "dfl-obj-j01i6y-dfl-clu-000017",
			"first_name": "Caden Christopher",
			"last_name": "Clark",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "411",
			"external_uid": "dfl-obj-j01sf3-dfl-clu-000010",
			"first_name": "David",
			"last_name": "Čolina",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "74",
			"external_uid": "dfl-obj-002gg7-dfl-clu-00000f",
			"first_name": "Pharrell Nnamdi",
			"last_name": "Collins",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "9",
			"external_uid": "dfl-obj-0026pm-dfl-clu-00000g",
			"first_name": "Kingsley Junior",
			"last_name": "Coman",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "540",
			"external_uid": "dfl-obj-j01i6k-dfl-clu-000007",
			"first_name": "Soumaïla",
			"last_name": "Coulibaly",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "622",
			"external_uid": "dfl-obj-002g2w-dfl-clu-00000d",
			"first_name": "Tanguy",
			"last_name": "Coulibaly",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "376",
			"external_uid": "dfl-obj-j01sxs-dfl-clu-000003",
			"first_name": "Nicolas Louis Marcel",
			"last_name": "Cozza",
			"team_code": "WOB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "195",
			"external_uid": "dfl-obj-j01bis-dfl-clu-000004",
			"first_name": "Tomáš",
			"last_name": "Čvančara",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "583",
			"external_uid": "dfl-obj-002g8k-dfl-clu-000002",
			"first_name": "Munas",
			"last_name": "Dabbur",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "396",
			"external_uid": "dfl-obj-0028co-dfl-clu-000010",
			"first_name": "Finn Gilbert",
			"last_name": "Dahmen",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "613",
			"external_uid": "dfl-obj-00021p-dfl-clu-000007",
			"first_name": "Mahmoud",
			"last_name": "Dahoud",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "472",
			"external_uid": "dfl-obj-j01ngw-dfl-clu-000002",
			"first_name": "Muhammed Mehmet",
			"last_name": "Damar",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "257",
			"external_uid": "dfl-obj-0027qn-dfl-clu-00000s",
			"first_name": "Lukas Stephan Horst",
			"last_name": "Daschner",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "20",
			"external_uid": "dfl-obj-002fwz-dfl-clu-00000g",
			"first_name": "Alphonso Boyle",
			"last_name": "Davies",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "16",
			"external_uid": "dfl-obj-j017re-dfl-clu-00000g",
			"first_name": "Matthijs",
			"last_name": "de Ligt",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "177",
			"external_uid": "dfl-obj-j01unl-dfl-clu-00000b",
			"first_name": "Arthur Augusto",
			"last_name": "de Matos Soares",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "431",
			"external_uid": "dfl-obj-002g1z-dfl-clu-00000v",
			"first_name": "Laurenz",
			"last_name": "Dehl",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "629",
			"external_uid": "dfl-obj-0027k5-dfl-clu-000002",
			"first_name": "Thomas Joseph",
			"last_name": "Delaney",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "560",
			"external_uid": "dfl-obj-0001uo-dfl-clu-00000b",
			"first_name": "Kerem",
			"last_name": "Demirbay",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "402",
			"external_uid": "dfl-obj-002gey-dfl-clu-000010",
			"first_name": "Ermedin",
			"last_name": "Demirović",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "574",
			"external_uid": "dfl-obj-002g02-dfl-clu-00000b",
			"first_name": "Moussa",
			"last_name": "Diaby",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "621",
			"external_uid": "dfl-obj-0027xf-dfl-clu-000017",
			"first_name": "Abdou-Lakhad",
			"last_name": "Diallo",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "590",
			"external_uid": "dfl-obj-j01k9z-dfl-clu-000008",
			"first_name": "Justin",
			"last_name": "Diehl",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "670",
			"external_uid": "dfl-obj-j01sng-dfl-clu-00000e",
			"first_name": "Tim-Justin",
			"last_name": "Dietrich",
			"team_code": "SVW",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "133",
			"external_uid": "dfl-obj-002g60-dfl-clu-000008",
			"first_name": "Florian",
			"last_name": "Dietz",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "595",
			"external_uid": "dfl-obj-j01khe-dfl-clu-000004",
			"first_name": "Ibrahim",
			"last_name": "Digberekou",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "686",
			"external_uid": "dfl-obj-j01qql-dfl-clu-000004",
			"first_name": "Florian",
			"last_name": "Dimmer",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "84",
			"external_uid": "dfl-obj-j01k77-dfl-clu-00000f",
			"first_name": "Éric Junior",
			"last_name": "Dina Ebimbe",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "496",
			"external_uid": "dfl-obj-002g8e-dfl-clu-000018",
			"first_name": "Eren Sami",
			"last_name": "Dinkçi",
			"team_code": "HDH",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "289",
			"external_uid": "dfl-obj-002gjn-dfl-clu-00000a",
			"first_name": "Ritsu",
			"last_name": "Dōan",
			"team_code": "SCF",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "439",
			"external_uid": "dfl-obj-j01gqo-dfl-clu-00000v",
			"first_name": "Danilho Raimundo",
			"last_name": "Doekhi",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "618",
			"external_uid": "dfl-obj-j017rl-dfl-clu-000002",
			"first_name": "Kasper",
			"last_name": "Dolberg Rasmussen",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "384",
			"external_uid": "dfl-obj-0027bi-dfl-clu-000010",
			"first_name": "Niklas Bernd",
			"last_name": "Dorsch",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "46",
			"external_uid": "dfl-obj-002fu8-dfl-clu-00000e",
			"first_name": "Eduardo",
			"last_name": "Dos Santos Haesler",
			"team_code": "SVW",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "548",
			"external_uid": "dfl-obj-0026zu-dfl-clu-000004",
			"first_name": "Mamadou",
			"last_name": "Doucouré",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "477",
			"external_uid": "dfl-obj-0027qt-dfl-clu-000018",
			"first_name": "Nikola",
			"last_name": "Dovedan",
			"team_code": "HDH",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "593",
			"external_uid": "dfl-obj-j01qpg-dfl-clu-000008",
			"first_name": "Damion Lamar",
			"last_name": "Downs",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "35",
			"external_uid": "dfl-obj-0001ce-dfl-clu-00000e",
			"first_name": "Marvin",
			"last_name": "Ducksch",
			"team_code": "SVW",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "240",
			"external_uid": "dfl-obj-j01rh2-dfl-clu-000007",
			"first_name": "Julien Dienda",
			"last_name": "Duranville",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "280",
			"external_uid": "dfl-obj-00258b-dfl-clu-00000a",
			"first_name": "Maximilian",
			"last_name": "Eggestein",
			"team_code": "SCF",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "113",
			"external_uid": "dfl-obj-002g82-dfl-clu-00000d",
			"first_name": "Lilian Niclas",
			"last_name": "Egloff",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "476",
			"external_uid": "dfl-obj-000082-dfl-clu-000018",
			"first_name": "Vitus",
			"last_name": "Eicher",
			"team_code": "HDH",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "187",
			"external_uid": "dfl-obj-00264n-dfl-clu-000004",
			"first_name": "Nico",
			"last_name": "Elvedi",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "614",
			"external_uid": "dfl-obj-002703-dfl-clu-000004",
			"first_name": "Breel Donald",
			"last_name": "Embolo",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "530",
			"external_uid": "dfl-obj-002g5p-dfl-clu-00000d",
			"first_name": "Wataru",
			"last_name": "Endo",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "405",
			"external_uid": "dfl-obj-j01g4p-dfl-clu-000010",
			"first_name": "Arne",
			"last_name": "Engels",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "584",
			"external_uid": "dfl-obj-002gat-dfl-clu-000017",
			"first_name": "José Ángel",
			"last_name": "Esmoris Tasende",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "244",
			"external_uid": "dfl-obj-00003j-dfl-clu-00000s",
			"first_name": "Michael",
			"last_name": "Esser",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "556",
			"external_uid": "dfl-obj-002ggg-dfl-clu-00000b",
			"first_name": "Joshua Chima",
			"last_name": "Eze",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "657",
			"external_uid": "dfl-obj-002gka-dfl-clu-00000a",
			"first_name": "Kimberly Chiwetalu Akwaeze",
			"last_name": "Ezekwem",
			"team_code": "SCF",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "575",
			"external_uid": "dfl-obj-j01gtf-dfl-clu-00000d",
			"first_name": "Wahidullah",
			"last_name": "Faghir",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "500",
			"external_uid": "dfl-obj-j01j8l-dfl-clu-000018",
			"first_name": "Frank",
			"last_name": "Feller",
			"team_code": "HDH",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "253",
			"external_uid": "dfl-obj-0027fu-dfl-clu-00000s",
			"first_name": "Bernardo",
			"last_name": "Fernandes da Silva Junior",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "331",
			"external_uid": "dfl-obj-002g08-dfl-clu-000006",
			"first_name": "Edimilson",
			"last_name": "Fernandes Ribeiro",
			"team_code": "M05",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "85",
			"external_uid": "dfl-obj-j01o6a-dfl-clu-00000f",
			"first_name": "Ignacio",
			"last_name": "Ferri Julia",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "149",
			"external_uid": "dfl-obj-j01snf-dfl-clu-000008",
			"first_name": "Max",
			"last_name": "Finkgräfe",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "367",
			"external_uid": "dfl-obj-j01fbp-dfl-clu-000003",
			"first_name": "Kilian",
			"last_name": "Fischer",
			"team_code": "WOB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "437",
			"external_uid": "dfl-obj-j01gli-dfl-clu-00000v",
			"first_name": "David Datro",
			"last_name": "Fofana",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "482",
			"external_uid": "dfl-obj-00266e-dfl-clu-000018",
			"first_name": "Jonas",
			"last_name": "Föhrenbach",
			"team_code": "HDH",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "511",
			"external_uid": "dfl-obj-00259d-dfl-clu-000017",
			"first_name": "Emil Peter",
			"last_name": "Forsberg",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "256",
			"external_uid": "dfl-obj-0027lo-dfl-clu-00000s",
			"first_name": "Philipp",
			"last_name": "Förster",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "161",
			"external_uid": "dfl-obj-002gnp-dfl-clu-00000b",
			"first_name": "Evans Timothy",
			"last_name": "Fosu-Mensah",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "381",
			"external_uid": "dfl-obj-0001u1-dfl-clu-000010",
			"first_name": "Raphael",
			"last_name": "Framberger",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "315",
			"external_uid": "dfl-obj-j01bvj-dfl-clu-000016",
			"first_name": "Bartol",
			"last_name": "Franjić",
			"team_code": "SVD",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "208",
			"external_uid": "dfl-obj-j01nun-dfl-clu-000004",
			"first_name": "Oscar Luigi",
			"last_name": "Fraulo",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "529",
			"external_uid": "dfl-obj-j01rhb-dfl-clu-000017",
			"first_name": "Fábio Leandro",
			"last_name": "Freitas Gouveia de Carvalho",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "43",
			"external_uid": "dfl-obj-0027bh-dfl-clu-00000e",
			"first_name": "Marco",
			"last_name": "Friedl",
			"team_code": "SVW",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "186",
			"external_uid": "dfl-obj-0002av-dfl-clu-000004",
			"first_name": "Marvin",
			"last_name": "Friedrich",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "168",
			"external_uid": "dfl-obj-002goi-dfl-clu-00000b",
			"first_name": "Jeremie Agyekum",
			"last_name": "Frimpong",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "105",
			"external_uid": "dfl-obj-0028i9-dfl-clu-00000d",
			"first_name": "Chris Jan",
			"last_name": "Führich",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "30",
			"external_uid": "dfl-obj-0000zm-dfl-clu-00000e",
			"first_name": "Niclas",
			"last_name": "Füllkrug",
			"team_code": "SVW",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "243",
			"external_uid": "dfl-obj-002g6g-dfl-clu-00000s",
			"first_name": "Cristian Esteban",
			"last_name": "Gamboa Luna",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "677",
			"external_uid": "dfl-obj-0028qy-dfl-clu-00000s",
			"first_name": "Silvère",
			"last_name": "Ganvoula M'boussy",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "71",
			"external_uid": "dfl-obj-j01cud-dfl-clu-00000f",
			"first_name": "Dario Ndubuisi",
			"last_name": "Gebuhr",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "454",
			"external_uid": "dfl-obj-0027kk-dfl-clu-000002",
			"first_name": "Dennis",
			"last_name": "Geiger",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "354",
			"external_uid": "dfl-obj-0001v4-dfl-clu-000003",
			"first_name": "Yannick",
			"last_name": "Gerhardt",
			"team_code": "WOB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "72",
			"external_uid": "dfl-obj-j01cuk-dfl-clu-00000f",
			"first_name": "Harpreet Singh",
			"last_name": "Ghotra",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "617",
			"external_uid": "dfl-obj-000187-dfl-clu-00000v",
			"first_name": "Niko",
			"last_name": "Gießelmann",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "616",
			"external_uid": "dfl-obj-0002eq-dfl-clu-000010",
			"first_name": "Rafał",
			"last_name": "Gikiewicz",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "481",
			"external_uid": "dfl-obj-002651-dfl-clu-000018",
			"first_name": "Benedikt Steffen",
			"last_name": "Gimber",
			"team_code": "HDH",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "273",
			"external_uid": "dfl-obj-0000zs-dfl-clu-00000a",
			"first_name": "Matthias Lukas",
			"last_name": "Ginter",
			"team_code": "SCF",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "305",
			"external_uid": "dfl-obj-0028o4-dfl-clu-000016",
			"first_name": "Klaus Fatmir",
			"last_name": "Gjasula",
			"team_code": "SVD",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "676",
			"external_uid": "dfl-obj-j01r7c-dfl-clu-00000d",
			"first_name": "Nicolas Roger",
			"last_name": "Glaus",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "7",
			"external_uid": "dfl-obj-0027g6-dfl-clu-00000g",
			"first_name": "Serge David",
			"last_name": "Gnabry",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "541",
			"external_uid": "dfl-obj-j019ht-dfl-clu-00000s",
			"first_name": "Jacek",
			"last_name": "Góralski",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "6",
			"external_uid": "dfl-obj-000191-dfl-clu-00000g",
			"first_name": "Leon Christoph",
			"last_name": "Goretzka",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "426",
			"external_uid": "dfl-obj-j019pz-dfl-clu-00000v",
			"first_name": "Robin Everardus",
			"last_name": "Gosens",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "62",
			"external_uid": "dfl-obj-00000h-dfl-clu-00000f",
			"first_name": "Mario",
			"last_name": "Götze",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "379",
			"external_uid": "dfl-obj-0026tp-dfl-clu-000010",
			"first_name": "Jeffrey",
			"last_name": "Gouweleeuw",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "58",
			"external_uid": "dfl-obj-0000g2-dfl-clu-00000f",
			"first_name": "Jens",
			"last_name": "Grahl",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "647",
			"external_uid": "dfl-obj-0028rv-dfl-clu-00000s",
			"first_name": "Paul",
			"last_name": "Grave",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "19",
			"external_uid": "dfl-obj-j01b8n-dfl-clu-00000g",
			"first_name": "Ryan Jiro",
			"last_name": "Gravenberch",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "270",
			"external_uid": "dfl-obj-00018o-dfl-clu-00000a",
			"first_name": "Michael",
			"last_name": "Gregoritsch",
			"team_code": "SCF",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "276",
			"external_uid": "dfl-obj-0001e6-dfl-clu-00000a",
			"first_name": "Vincenzo",
			"last_name": "Grifo",
			"team_code": "SCF",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "586",
			"external_uid": "dfl-obj-0027i0-dfl-clu-00000v",
			"first_name": "Hannes Lennart",
			"last_name": "Grill",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "450",
			"external_uid": "dfl-obj-00264x-dfl-clu-000002",
			"first_name": "Florian",
			"last_name": "Grillitsch",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "160",
			"external_uid": "dfl-obj-j016j2-dfl-clu-00000b",
			"first_name": "Alejandro",
			"last_name": "Grimaldo Garcia",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "31",
			"external_uid": "dfl-obj-0026gy-dfl-clu-00000e",
			"first_name": "Christian",
			"last_name": "Groß",
			"team_code": "SVW",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "349",
			"external_uid": "dfl-obj-j01nd7-dfl-clu-000006",
			"first_name": "Brajan",
			"last_name": "Gruda",
			"team_code": "M05",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "49",
			"external_uid": "dfl-obj-002fub-dfl-clu-00000e",
			"first_name": "Ilia Iliev",
			"last_name": "Gruev",
			"team_code": "SVW",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "12",
			"external_uid": "dfl-obj-0027ah-dfl-clu-00000g",
			"first_name": "Raphaël Adelino José",
			"last_name": "Guerreiro",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "98",
			"external_uid": "dfl-obj-00278s-dfl-clu-00000d",
			"first_name": "Serhou Yadaly",
			"last_name": "Guirassy",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "503",
			"external_uid": "dfl-obj-002662-dfl-clu-000017",
			"first_name": "Péter",
			"last_name": "Gulácsi",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "269",
			"external_uid": "dfl-obj-0000wf-dfl-clu-00000a",
			"first_name": "Manuel",
			"last_name": "Gulde",
			"team_code": "SCF",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "400",
			"external_uid": "dfl-obj-002gjf-dfl-clu-000010",
			"first_name": "Robert Krzysztof",
			"last_name": "Gumny",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "275",
			"external_uid": "dfl-obj-00017v-dfl-clu-00000a",
			"first_name": "Christian",
			"last_name": "Günter",
			"team_code": "SCF",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "569",
			"external_uid": "dfl-obj-j01cn9-dfl-clu-000010",
			"first_name": "Lasse",
			"last_name": "Günther",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "566",
			"external_uid": "dfl-obj-002ggp-dfl-clu-000007",
			"first_name": "Göktan",
			"last_name": "Gürpüz",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "557",
			"external_uid": "dfl-obj-j01dgi-dfl-clu-000017",
			"first_name": "Joško",
			"last_name": "Gvardiol",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "418",
			"external_uid": "dfl-obj-0002dw-dfl-clu-00000v",
			"first_name": "Janik",
			"last_name": "Haberer",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "536",
			"external_uid": "dfl-obj-0026qj-dfl-clu-000006",
			"first_name": "Alexander",
			"last_name": "Hack",
			"team_code": "M05",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "193",
			"external_uid": "dfl-obj-0027rm-dfl-clu-000004",
			"first_name": "Robin",
			"last_name": "Hack",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "620",
			"external_uid": "dfl-obj-0001fw-dfl-clu-000010",
			"first_name": "André",
			"last_name": "Hahn",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "516",
			"external_uid": "dfl-obj-002fx0-dfl-clu-000017",
			"first_name": "Amadou",
			"last_name": "Haidara",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "223",
			"external_uid": "dfl-obj-0027ro-dfl-clu-000007",
			"first_name": "Sébastien Romain Teddy",
			"last_name": "Haller",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "578",
			"external_uid": "dfl-obj-0001f4-dfl-clu-000017",
			"first_name": "Marcel",
			"last_name": "Halstenberg",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "336",
			"external_uid": "dfl-obj-j019xm-dfl-clu-000006",
			"first_name": "Andreas Schjölberg",
			"last_name": "Hanche-Olsen",
			"team_code": "M05",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "93",
			"external_uid": "dfl-obj-0002e5-dfl-clu-00000d",
			"first_name": "Genki",
			"last_name": "Haraguchi",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "532",
			"external_uid": "dfl-obj-0028lx-dfl-clu-000017",
			"first_name": "Fabrice Daniel",
			"last_name": "Hartmann",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "266",
			"external_uid": "dfl-obj-002gns-dfl-clu-00000s",
			"first_name": "Luis",
			"last_name": "Hartwig",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "57",
			"external_uid": "dfl-obj-0000tn-dfl-clu-00000f",
			"first_name": "Makoto",
			"last_name": "Hasebe",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "654",
			"external_uid": "dfl-obj-00266x-dfl-clu-000017",
			"first_name": "Ørjan",
			"last_name": "Håskjold Nyland",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "80",
			"external_uid": "dfl-obj-j01g0t-dfl-clu-00000f",
			"first_name": "Jens Petter",
			"last_name": "Hauge",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "218",
			"external_uid": "dfl-obj-0002hb-dfl-clu-000007",
			"first_name": "Thorgan Ganael Francis",
			"last_name": "Hazard",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "417",
			"external_uid": "dfl-obj-0000xu-dfl-clu-00000v",
			"first_name": "Dominique",
			"last_name": "Heintz",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "512",
			"external_uid": "dfl-obj-002670-dfl-clu-000017",
			"first_name": "Benjamin Paa Kwesi",
			"last_name": "Henrichs",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "585",
			"external_uid": "dfl-obj-002g0i-dfl-clu-00000g",
			"first_name": "Lucas François Bernard",
			"last_name": "Hernández",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "181",
			"external_uid": "dfl-obj-0000nw-dfl-clu-000004",
			"first_name": "Patrick",
			"last_name": "Herrmann",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "596",
			"external_uid": "dfl-obj-j01qqo-dfl-clu-000003",
			"first_name": "Max",
			"last_name": "Herrmann",
			"team_code": "WOB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "172",
			"external_uid": "dfl-obj-j01k76-dfl-clu-00000b",
			"first_name": "Piero Martin",
			"last_name": "Hincapie Reyna",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "170",
			"external_uid": "dfl-obj-j01g0j-dfl-clu-00000b",
			"first_name": "Adam",
			"last_name": "Hložek",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "271",
			"external_uid": "dfl-obj-0000d9-dfl-clu-00000a",
			"first_name": "Nicolas",
			"last_name": "Höfler",
			"team_code": "SCF",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "246",
			"external_uid": "dfl-obj-0000yv-dfl-clu-00000s",
			"first_name": "Philipp",
			"last_name": "Hofmann",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "153",
			"external_uid": "dfl-obj-0001e5-dfl-clu-00000b",
			"first_name": "Jonas",
			"last_name": "Hofmann",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "600",
			"external_uid": "dfl-obj-002g0l-dfl-clu-000006",
			"first_name": "Marcus",
			"last_name": "Højriis Ingvartsen",
			"team_code": "M05",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "278",
			"external_uid": "dfl-obj-0027b9-dfl-clu-00000a",
			"first_name": "Lucas",
			"last_name": "Höler",
			"team_code": "SCF",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "298",
			"external_uid": "dfl-obj-000158-dfl-clu-000016",
			"first_name": "Fabian",
			"last_name": "Holland",
			"team_code": "SVD",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "433",
			"external_uid": "dfl-obj-j01dku-dfl-clu-00000v",
			"first_name": "Benedict",
			"last_name": "Hollerbach",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "549",
			"external_uid": "dfl-obj-0025j8-dfl-clu-00000s",
			"first_name": "Gerrit",
			"last_name": "Holtmann",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "197",
			"external_uid": "dfl-obj-j01d4g-dfl-clu-000004",
			"first_name": "Franck",
			"last_name": "Honorat",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "309",
			"external_uid": "dfl-obj-0028qv-dfl-clu-000016",
			"first_name": "Mathias",
			"last_name": "Honsak",
			"team_code": "SVD",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "666",
			"external_uid": "dfl-obj-j01def-dfl-clu-00000d",
			"first_name": "Mattis",
			"last_name": "Hoppe",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "656",
			"external_uid": "dfl-obj-0000m0-dfl-clu-000008",
			"first_name": "Timo",
			"last_name": "Horn",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "314",
			"external_uid": "dfl-obj-j01a4z-dfl-clu-000016",
			"first_name": "Fraser David Ingham",
			"last_name": "Hornby",
			"team_code": "SVD",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "673",
			"external_uid": "dfl-obj-j01cub-dfl-clu-00000f",
			"first_name": "Jannik Reiner",
			"last_name": "Horz",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "151",
			"external_uid": "dfl-obj-0026ny-dfl-clu-00000b",
			"first_name": "Lukáš",
			"last_name": "Hrádecký",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "131",
			"external_uid": "dfl-obj-0027b6-dfl-clu-000008",
			"first_name": "Timo Bernd",
			"last_name": "Hübers",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "609",
			"external_uid": "dfl-obj-j01a8n-dfl-clu-00000b",
			"first_name": "Callum James",
			"last_name": "Hudson-Odoi",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "22",
			"external_uid": "dfl-obj-j01cnd-dfl-clu-00000g",
			"first_name": "Tom Ritzy",
			"last_name": "Hülsmann",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "211",
			"external_uid": "dfl-obj-000007-dfl-clu-000007",
			"first_name": "Mats Julian",
			"last_name": "Hummels",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "142",
			"external_uid": "dfl-obj-j01dvc-dfl-clu-000008",
			"first_name": "Denis",
			"last_name": "Huseinbašić",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "25",
			"external_uid": "dfl-obj-j01eaz-dfl-clu-00000g",
			"first_name": "Arijon",
			"last_name": "Ibrahimović",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "203",
			"external_uid": "dfl-obj-j01h9v-dfl-clu-000004",
			"first_name": "Ko",
			"last_name": "Itakura",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "672",
			"external_uid": "dfl-obj-002g6m-dfl-clu-000004",
			"first_name": "Jacob Michael",
			"last_name": "Italiano",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "117",
			"external_uid": "dfl-obj-j01ill-dfl-clu-00000d",
			"first_name": "Hiroki",
			"last_name": "Ito",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "425",
			"external_uid": "dfl-obj-002860-dfl-clu-00000v",
			"first_name": "Paul",
			"last_name": "Jaeckel",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "563",
			"external_uid": "dfl-obj-0028th-dfl-clu-000017",
			"first_name": "Frederik",
			"last_name": "Jäkel",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "78",
			"external_uid": "dfl-obj-j01fx8-dfl-clu-00000f",
			"first_name": "Kristijan",
			"last_name": "Jakić",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "502",
			"external_uid": "dfl-obj-j01w0j-dfl-clu-000018",
			"first_name": "Luka",
			"last_name": "Janeš",
			"team_code": "HDH",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "661",
			"external_uid": "dfl-obj-j01cni-dfl-clu-00000g",
			"first_name": "Justin",
			"last_name": "Janitzek",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "643",
			"external_uid": "dfl-obj-j016c3-dfl-clu-00000s",
			"first_name": "Saidy",
			"last_name": "Janko",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "180",
			"external_uid": "dfl-obj-0000oe-dfl-clu-000004",
			"first_name": "Tony",
			"last_name": "Jantschke",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "501",
			"external_uid": "dfl-obj-j01vxb-dfl-clu-000018",
			"first_name": "Seedy",
			"last_name": "Jarju",
			"team_code": "HDH",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "388",
			"external_uid": "dfl-obj-0028od-dfl-clu-000010",
			"first_name": "Hans Fredrik",
			"last_name": "Jensen",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "377",
			"external_uid": "dfl-obj-j01szz-dfl-clu-000003",
			"first_name": "Moritz",
			"last_name": "Jenz",
			"team_code": "WOB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "108",
			"external_uid": "dfl-obj-002fvr-dfl-clu-00000d",
			"first_name": "Wooyeong",
			"last_name": "Jeong",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "646",
			"external_uid": "dfl-obj-j0165s-dfl-clu-00000s",
			"first_name": "Marko",
			"last_name": "Johansson",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "465",
			"external_uid": "dfl-obj-002gcp-dfl-clu-000002",
			"first_name": "Marco",
			"last_name": "John",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "33",
			"external_uid": "dfl-obj-00019u-dfl-clu-00000e",
			"first_name": "Anthony",
			"last_name": "Jung",
			"team_code": "SVW",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "440",
			"external_uid": "dfl-obj-j01gso-dfl-clu-00000v",
			"first_name": "Josip",
			"last_name": "Juranovic",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "457",
			"external_uid": "dfl-obj-0028ba-dfl-clu-000002",
			"first_name": "Julian",
			"last_name": "Justvan",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "463",
			"external_uid": "dfl-obj-002fxb-dfl-clu-000002",
			"first_name": "Ozan Muhammed",
			"last_name": "Kabak",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "447",
			"external_uid": "dfl-obj-002650-dfl-clu-000002",
			"first_name": "Pavel",
			"last_name": "Kadeřábek",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "122",
			"external_uid": "dfl-obj-0027ax-dfl-clu-000008",
			"first_name": "Florian",
			"last_name": "Kainz",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "605",
			"external_uid": "dfl-obj-0027sc-dfl-clu-00000f",
			"first_name": "Daichi",
			"last_name": "Kamada",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "238",
			"external_uid": "dfl-obj-j01gsk-dfl-clu-000007",
			"first_name": "Abdoulaye",
			"last_name": "Kamara",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "369",
			"external_uid": "dfl-obj-j01fye-dfl-clu-000003",
			"first_name": "Jakub Piotr",
			"last_name": "Kamiński",
			"team_code": "WOB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "504",
			"external_uid": "dfl-obj-0001bn-dfl-clu-000017",
			"first_name": "Kevin",
			"last_name": "Kampl",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "5",
			"external_uid": "dfl-obj-j00zz3-dfl-clu-00000g",
			"first_name": "Harry Edward",
			"last_name": "Kane",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "96",
			"external_uid": "dfl-obj-0027sd-dfl-clu-00000d",
			"first_name": "Atakan",
			"last_name": "Karazor",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "317",
			"external_uid": "dfl-obj-j01c0j-dfl-clu-000016",
			"first_name": "Emir",
			"last_name": "Karic",
			"team_code": "SVD",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "111",
			"external_uid": "dfl-obj-j01dew-dfl-clu-00000d",
			"first_name": "Thomas",
			"last_name": "Kastanaras",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "689",
			"external_uid": "dfl-obj-002fym-dfl-clu-000003",
			"first_name": "Lino Björn",
			"last_name": "Kasten",
			"team_code": "WOB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "681",
			"external_uid": "dfl-obj-j01dw4-dfl-clu-000010",
			"first_name": "Daniel",
			"last_name": "Katic",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "110",
			"external_uid": "dfl-obj-002g5r-dfl-clu-00000d",
			"first_name": "Silas",
			"last_name": "Katompa Mvumpa",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "139",
			"external_uid": "dfl-obj-002fw7-dfl-clu-000008",
			"first_name": "Noah",
			"last_name": "Katterbach",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "434",
			"external_uid": "dfl-obj-j01eaq-dfl-clu-00000v",
			"first_name": "Mikkel",
			"last_name": "Kaufmann Sörensen",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "39",
			"external_uid": "dfl-obj-0027aw-dfl-clu-00000e",
			"first_name": "Naby Laye",
			"last_name": "Keïta",
			"team_code": "SVW",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "286",
			"external_uid": "dfl-obj-0028sy-dfl-clu-00000a",
			"first_name": "Yannik",
			"last_name": "Keitel",
			"team_code": "SCF",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "492",
			"external_uid": "dfl-obj-002fyu-dfl-clu-000018",
			"first_name": "Thomas Leon",
			"last_name": "Keller",
			"team_code": "HDH",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "443",
			"external_uid": "dfl-obj-j01nd9-dfl-clu-00000v",
			"first_name": "Aljoscha",
			"last_name": "Kemlein",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "297",
			"external_uid": "dfl-obj-0000da-dfl-clu-000016",
			"first_name": "Tobias",
			"last_name": "Kempe",
			"team_code": "SVD",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "419",
			"external_uid": "dfl-obj-0001ea-dfl-clu-00000v",
			"first_name": "Rani",
			"last_name": "Khedira",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "140",
			"external_uid": "dfl-obj-002g0r-dfl-clu-000008",
			"first_name": "Luca Jannis",
			"last_name": "Kilian",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "17",
			"external_uid": "dfl-obj-j01ae6-dfl-clu-00000g",
			"first_name": "Minjae",
			"last_name": "Kim",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "8",
			"external_uid": "dfl-obj-0002f5-dfl-clu-00000g",
			"first_name": "Joshua Walter",
			"last_name": "Kimmich",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "321",
			"external_uid": "dfl-obj-002gm9-dfl-clu-000016",
			"first_name": "Christoph",
			"last_name": "Klarer",
			"team_code": "SVD",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "683",
			"external_uid": "dfl-obj-002fw1-dfl-clu-000010",
			"first_name": "Daniel",
			"last_name": "Klein",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "479",
			"external_uid": "dfl-obj-0001hg-dfl-clu-000018",
			"first_name": "Tim",
			"last_name": "Kleindienst",
			"team_code": "HDH",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "358",
			"external_uid": "dfl-obj-0028bb-dfl-clu-000003",
			"first_name": "Niklas",
			"last_name": "Klinger",
			"team_code": "WOB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "510",
			"external_uid": "dfl-obj-0002ad-dfl-clu-000017",
			"first_name": "Lukas Manuel",
			"last_name": "Klostermann",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "77",
			"external_uid": "dfl-obj-002gkg-dfl-clu-00000f",
			"first_name": "Ansgar",
			"last_name": "Knauff",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "415",
			"external_uid": "dfl-obj-0000ue-dfl-clu-00000v",
			"first_name": "Robin",
			"last_name": "Knoche",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "134",
			"external_uid": "dfl-obj-0027sx-dfl-clu-000008",
			"first_name": "Matthias",
			"last_name": "Köbbing",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "222",
			"external_uid": "dfl-obj-0027bl-dfl-clu-000007",
			"first_name": "Gregor",
			"last_name": "Kobel",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "64",
			"external_uid": "dfl-obj-0025w7-dfl-clu-00000f",
			"first_name": "Robin Leon",
			"last_name": "Koch",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "329",
			"external_uid": "dfl-obj-00015h-dfl-clu-000006",
			"first_name": "Dominik",
			"last_name": "Kohr",
			"team_code": "M05",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "83",
			"external_uid": "dfl-obj-j01h47-dfl-clu-00000f",
			"first_name": "Randal",
			"last_name": "Kolo Muani",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "409",
			"external_uid": "dfl-obj-j01kev-dfl-clu-000010",
			"first_name": "Mert",
			"last_name": "Kömür",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "202",
			"external_uid": "dfl-obj-j01gic-dfl-clu-000004",
			"first_name": "Kouadio Emmanuel",
			"last_name": "Koné",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "164",
			"external_uid": "dfl-obj-j01d3p-dfl-clu-00000b",
			"first_name": "Kouakou Odilon Dorgeless",
			"last_name": "Kossounou",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "497",
			"external_uid": "dfl-obj-002gkk-dfl-clu-000018",
			"first_name": "Tim",
			"last_name": "Köther",
			"team_code": "HDH",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "382",
			"external_uid": "dfl-obj-002g52-dfl-clu-000010",
			"first_name": "Tomáš",
			"last_name": "Koubek",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "577",
			"external_uid": "dfl-obj-j01nik-dfl-clu-000010",
			"first_name": "Henri",
			"last_name": "Koudossou",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "162",
			"external_uid": "dfl-obj-j01buw-dfl-clu-00000b",
			"first_name": "Matěj",
			"last_name": "Kovář",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "42",
			"external_uid": "dfl-obj-002fxt-dfl-clu-00000e",
			"first_name": "Dawid Igor",
			"last_name": "Kownacki",
			"team_code": "SVW",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "430",
			"external_uid": "dfl-obj-j01bvq-dfl-clu-00000v",
			"first_name": "Alex",
			"last_name": "Král",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "446",
			"external_uid": "dfl-obj-0026tu-dfl-clu-000002",
			"first_name": "Andrej",
			"last_name": "Kramarić",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "184",
			"external_uid": "dfl-obj-00003l-dfl-clu-000004",
			"first_name": "Christoph",
			"last_name": "Kramer",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "23",
			"external_uid": "dfl-obj-j01cns-dfl-clu-00000g",
			"first_name": "Frans",
			"last_name": "Krätzig",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "340",
			"external_uid": "dfl-obj-0028ti-dfl-clu-000006",
			"first_name": "Tom",
			"last_name": "Krauß",
			"team_code": "M05",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "274",
			"external_uid": "dfl-obj-00012y-dfl-clu-00000a",
			"first_name": "Lukas",
			"last_name": "Kübler",
			"team_code": "SCF",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "483",
			"external_uid": "dfl-obj-002g96-dfl-clu-000018",
			"first_name": "Christian",
			"last_name": "Kühlwetter",
			"team_code": "HDH",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "627",
			"external_uid": "dfl-obj-0028q6-dfl-clu-00000s",
			"first_name": "Pierre",
			"last_name": "Kunde Malong",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "587",
			"external_uid": "dfl-obj-j01i6b-dfl-clu-00000d",
			"first_name": "Alou",
			"last_name": "Kuol",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "260",
			"external_uid": "dfl-obj-0028g1-dfl-clu-00000s",
			"first_name": "Moritz-Broni",
			"last_name": "Kwarteng",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "285",
			"external_uid": "dfl-obj-002fzd-dfl-clu-00000a",
			"first_name": "Daniel-Kofi",
			"last_name": "Kyereh",
			"team_code": "SCF",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "368",
			"external_uid": "dfl-obj-002giz-dfl-clu-000003",
			"first_name": "Maxence",
			"last_name": "Lacroix",
			"team_code": "WOB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "435",
			"external_uid": "dfl-obj-j01fr5-dfl-clu-00000v",
			"first_name": "Aissa Bilal",
			"last_name": "Laidouni",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "10",
			"external_uid": "dfl-obj-0027t1-dfl-clu-00000g",
			"first_name": "Konrad",
			"last_name": "Laimer",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "182",
			"external_uid": "dfl-obj-002g1q-dfl-clu-000004",
			"first_name": "Stefan",
			"last_name": "Lainer",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "678",
			"external_uid": "dfl-obj-002gav-dfl-clu-00000s",
			"first_name": "Vasileios-Konstantinos",
			"last_name": "Lampropoulos",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "375",
			"external_uid": "dfl-obj-j01q31-dfl-clu-000003",
			"first_name": "Felix Finn",
			"last_name": "Lange",
			"team_code": "WOB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "90",
			"external_uid": "dfl-obj-j01vx4-dfl-clu-00000f",
			"first_name": "Hugo Emanuel",
			"last_name": "Larsson",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "581",
			"external_uid": "dfl-obj-j01cxj-dfl-clu-000006",
			"first_name": "Lucas",
			"last_name": "Laux",
			"team_code": "M05",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "337",
			"external_uid": "dfl-obj-0028rj-dfl-clu-000006",
			"first_name": "Jae-sung",
			"last_name": "Lee",
			"team_code": "M05",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "334",
			"external_uid": "dfl-obj-002708-dfl-clu-000006",
			"first_name": "Maxim",
			"last_name": "Leitsch",
			"team_code": "M05",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "290",
			"external_uid": "dfl-obj-002gkz-dfl-clu-00000a",
			"first_name": "Tan-Kenneth Jerico",
			"last_name": "Leka-Schmidt",
			"team_code": "SCF",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "639",
			"external_uid": "dfl-obj-002gbw-dfl-clu-000008",
			"first_name": "Tim",
			"last_name": "Lemperle",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "568",
			"external_uid": "dfl-obj-002fuj-dfl-clu-000010",
			"first_name": "Benjamin",
			"last_name": "Leneis",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "65",
			"external_uid": "dfl-obj-0026jv-dfl-clu-00000f",
			"first_name": "Christopher",
			"last_name": "Lenz",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "109",
			"external_uid": "dfl-obj-002g41-dfl-clu-00000d",
			"first_name": "Jamie",
			"last_name": "Leweling",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "279",
			"external_uid": "dfl-obj-0027wp-dfl-clu-00000a",
			"first_name": "Philipp",
			"last_name": "Lienhart",
			"team_code": "SCF",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "137",
			"external_uid": "dfl-obj-002glj-dfl-clu-000008",
			"first_name": "Dimitrios",
			"last_name": "Limnios",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "82",
			"external_uid": "dfl-obj-j01gtd-dfl-clu-00000f",
			"first_name": "Jesper Gränge",
			"last_name": "Lindström",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "138",
			"external_uid": "dfl-obj-j01bgm-dfl-clu-000008",
			"first_name": "Dejan",
			"last_name": "Ljubicic",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "154",
			"external_uid": "dfl-obj-000177-dfl-clu-00000b",
			"first_name": "Niklas Uwe Bernd",
			"last_name": "Lomb",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "252",
			"external_uid": "dfl-obj-j0157j-dfl-clu-00000s",
			"first_name": "Noah",
			"last_name": "Loosli",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "671",
			"external_uid": "dfl-obj-002gkm-dfl-clu-00000e",
			"first_name": "Louis Sascha",
			"last_name": "Lord",
			"team_code": "SVW",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "247",
			"external_uid": "dfl-obj-000199-dfl-clu-00000s",
			"first_name": "Anthony",
			"last_name": "Losilla",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "226",
			"external_uid": "dfl-obj-002fyb-dfl-clu-000007",
			"first_name": "Marcel Laurenz",
			"last_name": "Lotka",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "73",
			"external_uid": "dfl-obj-j01dyz-dfl-clu-00000f",
			"first_name": "Mehdi",
			"last_name": "Loune",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "32",
			"external_uid": "dfl-obj-0000dt-dfl-clu-00000e",
			"first_name": "Leonardo Jesus",
			"last_name": "Loureiro Bittencourt",
			"team_code": "SVW",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "403",
			"external_uid": "dfl-obj-j01dw0-dfl-clu-000010",
			"first_name": "Marcel",
			"last_name": "Lubik",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "520",
			"external_uid": "dfl-obj-j01dov-dfl-clu-000017",
			"first_name": "Castello Junior",
			"last_name": "Lukeba",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "688",
			"external_uid": "dfl-obj-j018lq-dfl-clu-00000b",
			"first_name": "Andrey",
			"last_name": "Lunev",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "56",
			"external_uid": "dfl-obj-j01qja-dfl-clu-00000e",
			"first_name": "Senne Maaike",
			"last_name": "Lynen",
			"team_code": "SVW",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "659",
			"external_uid": "dfl-obj-002gh7-dfl-clu-00000v",
			"first_name": "Tim Luis",
			"last_name": "Maciejewski",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "356",
			"external_uid": "dfl-obj-j0159a-dfl-clu-000003",
			"first_name": "Joakim",
			"last_name": "Maehle Pedersen",
			"team_code": "WOB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "322",
			"external_uid": "dfl-obj-j01k2g-dfl-clu-000016",
			"first_name": "Matej",
			"last_name": "Maglica",
			"team_code": "SVD",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "390",
			"external_uid": "dfl-obj-0027m6-dfl-clu-000010",
			"first_name": "Arne Carl",
			"last_name": "Maier",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "135",
			"external_uid": "dfl-obj-0028bd-dfl-clu-000008",
			"first_name": "Linton",
			"last_name": "Maina",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "478",
			"external_uid": "dfl-obj-0001k8-dfl-clu-000018",
			"first_name": "Patrick",
			"last_name": "Mainka",
			"team_code": "HDH",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "364",
			"external_uid": "dfl-obj-j01be1-dfl-clu-000003",
			"first_name": "Lovro",
			"last_name": "Majer",
			"team_code": "WOB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "296",
			"external_uid": "dfl-obj-j01ib2-dfl-clu-00000a",
			"first_name": "Jordy",
			"last_name": "Makengo Basambundu",
			"team_code": "SCF",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "228",
			"external_uid": "dfl-obj-j01b65-dfl-clu-000007",
			"first_name": "Donyell",
			"last_name": "Malen",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "543",
			"external_uid": "dfl-obj-0028m4-dfl-clu-000010",
			"first_name": "Maurice Maximilian",
			"last_name": "Malone",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "487",
			"external_uid": "dfl-obj-0028bp-dfl-clu-000018",
			"first_name": "Lennard",
			"last_name": "Maloney",
			"team_code": "HDH",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "350",
			"external_uid": "dfl-obj-j01x9d-dfl-clu-000006",
			"first_name": "David",
			"last_name": "Mamutovic",
			"team_code": "M05",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "558",
			"external_uid": "dfl-obj-j0117h-dfl-clu-00000g",
			"first_name": "Sadio",
			"last_name": "Mané",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "313",
			"external_uid": "dfl-obj-002g11-dfl-clu-000016",
			"first_name": "Braydon Marvin",
			"last_name": "Manu",
			"team_code": "SVD",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "75",
			"external_uid": "dfl-obj-002gb9-dfl-clu-00000f",
			"first_name": "Omar Khaled Mohamed Abd Elsala",
			"last_name": "Marmoush",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "144",
			"external_uid": "dfl-obj-002gko-dfl-clu-000008",
			"first_name": "Eric",
			"last_name": "Martel",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "615",
			"external_uid": "dfl-obj-0028s9-dfl-clu-000006",
			"first_name": "Aarón",
			"last_name": "Martín Caricol",
			"team_code": "M05",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "662",
			"external_uid": "dfl-obj-j01cnj-dfl-clu-00000g",
			"first_name": "Gabriel",
			"last_name": "Marušić",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "264",
			"external_uid": "dfl-obj-002gm0-dfl-clu-00000s",
			"first_name": "Erhan",
			"last_name": "Mašović",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "104",
			"external_uid": "dfl-obj-0028hi-dfl-clu-00000d",
			"first_name": "Roberto",
			"last_name": "Massimo",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "533",
			"external_uid": "dfl-obj-002gmv-dfl-clu-00000f",
			"first_name": "Igor",
			"last_name": "Matanović",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "106",
			"external_uid": "dfl-obj-002g8o-dfl-clu-00000d",
			"first_name": "Konstantinos",
			"last_name": "Mavropanos",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "63",
			"external_uid": "dfl-obj-00029t-dfl-clu-00000f",
			"first_name": "Philipp Martin",
			"last_name": "Max",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "18",
			"external_uid": "dfl-obj-j01apo-dfl-clu-00000g",
			"first_name": "Noussair",
			"last_name": "Mazraoui",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "171",
			"external_uid": "dfl-obj-j01k4c-dfl-clu-00000b",
			"first_name": "Noah",
			"last_name": "Mbamba-Muanda",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "682",
			"external_uid": "dfl-obj-j01qlc-dfl-clu-000010",
			"first_name": "Josué Emanuel",
			"last_name": "Mbila",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "48",
			"external_uid": "dfl-obj-0028sd-dfl-clu-00000e",
			"first_name": "Jean-Manuel",
			"last_name": "Mbom",
			"team_code": "SVW",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "625",
			"external_uid": "dfl-obj-0001z6-dfl-clu-000007",
			"first_name": "Anthony",
			"last_name": "Mbu Agogo Modeste",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "406",
			"external_uid": "dfl-obj-j01idq-dfl-clu-000010",
			"first_name": "Nathanael",
			"last_name": "Mbuku",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "302",
			"external_uid": "dfl-obj-00265x-dfl-clu-000016",
			"first_name": "Marvin",
			"last_name": "Mehlem",
			"team_code": "SVD",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "216",
			"external_uid": "dfl-obj-002gd8-dfl-clu-000007",
			"first_name": "Thomas",
			"last_name": "Meunier",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "213",
			"external_uid": "dfl-obj-0028fc-dfl-clu-000007",
			"first_name": "Alexander Niklas",
			"last_name": "Meyer-Schade",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "380",
			"external_uid": "dfl-obj-00028b-dfl-clu-000010",
			"first_name": "Sven",
			"last_name": "Michel",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "118",
			"external_uid": "dfl-obj-j01k8d-dfl-clu-00000d",
			"first_name": "Enzo",
			"last_name": "Millot",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "120",
			"external_uid": "dfl-obj-j01vza-dfl-clu-00000d",
			"first_name": "Jovan",
			"last_name": "Milošević",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "95",
			"external_uid": "dfl-obj-0025ad-dfl-clu-00000d",
			"first_name": "Maximilian",
			"last_name": "Mittelstädt",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "679",
			"external_uid": "dfl-obj-j01k6y-dfl-clu-000006",
			"first_name": "Kaito",
			"last_name": "Mizuta",
			"team_code": "M05",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "650",
			"external_uid": "dfl-obj-00265f-dfl-clu-00000v",
			"first_name": "Kevin",
			"last_name": "Möhwald",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "114",
			"external_uid": "dfl-obj-002gau-dfl-clu-00000d",
			"first_name": "Clinton Lemere",
			"last_name": "Mola",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "174",
			"external_uid": "dfl-obj-j01qrv-dfl-clu-00000b",
			"first_name": "Madi",
			"last_name": "Monamay Nsosemo",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "427",
			"external_uid": "dfl-obj-j019t5-dfl-clu-00000v",
			"first_name": "Diogo Filipe",
			"last_name": "Monteiro Pinto Leite",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "231",
			"external_uid": "dfl-obj-002g2t-dfl-clu-000007",
			"first_name": "Mateu Jaume",
			"last_name": "Morey Bauza",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "523",
			"external_uid": "dfl-obj-j01g2s-dfl-clu-000017",
			"first_name": "Ilaix",
			"last_name": "Moriba Kourouma Kourouma",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "663",
			"external_uid": "dfl-obj-j01cqk-dfl-clu-00000g",
			"first_name": "Liam John",
			"last_name": "Morrison",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "237",
			"external_uid": "dfl-obj-002gjz-dfl-clu-000007",
			"first_name": "Youssoufa",
			"last_name": "Moukoko",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "319",
			"external_uid": "dfl-obj-j01fbk-dfl-clu-000016",
			"first_name": "Andreas",
			"last_name": "Müller",
			"team_code": "SVD",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "475",
			"external_uid": "dfl-obj-00006b-dfl-clu-000018",
			"first_name": "Kevin",
			"last_name": "Müller",
			"team_code": "HDH",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "281",
			"external_uid": "dfl-obj-0027u0-dfl-clu-00000a",
			"first_name": "Florian Lorenz",
			"last_name": "Müller",
			"team_code": "SCF",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "4",
			"external_uid": "dfl-obj-0000ia-dfl-clu-00000g",
			"first_name": "Thomas",
			"last_name": "Müller",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "304",
			"external_uid": "dfl-obj-0025tq-dfl-clu-000016",
			"first_name": "Jannik",
			"last_name": "Müller",
			"team_code": "SVD",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "178",
			"external_uid": "dfl-obj-j01x8m-dfl-clu-00000b",
			"first_name": "Reno Morris",
			"last_name": "Münz",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "21",
			"external_uid": "dfl-obj-002gcr-dfl-clu-00000g",
			"first_name": "Jamal",
			"last_name": "Musiala",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "582",
			"external_uid": "dfl-obj-002ghg-dfl-clu-000006",
			"first_name": "Suliman Marlon",
			"last_name": "Mustapha",
			"team_code": "M05",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "100",
			"external_uid": "dfl-obj-00286v-dfl-clu-00000d",
			"first_name": "Nikolas Terkelsen",
			"last_name": "Nartey",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "608",
			"external_uid": "dfl-obj-0028q3-dfl-clu-00000f",
			"first_name": "Evan Obite",
			"last_name": "Ndicka",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "200",
			"external_uid": "dfl-obj-002gc7-dfl-clu-000004",
			"first_name": "Luca",
			"last_name": "Netz",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "1",
			"external_uid": "dfl-obj-0000i4-dfl-clu-00000g",
			"first_name": "Manuel Peter",
			"last_name": "Neuer",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "191",
			"external_uid": "dfl-obj-002706-dfl-clu-000004",
			"first_name": "Florian Christian",
			"last_name": "Neuhaus",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "690",
			"external_uid": "dfl-obj-002ghk-dfl-clu-00000b",
			"first_name": "Maximilian",
			"last_name": "Neutgens",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "76",
			"external_uid": "dfl-obj-002gc8-dfl-clu-00000f",
			"first_name": "Ngankam Jessic Gaïtan",
			"last_name": "Ngankam",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "209",
			"external_uid": "dfl-obj-j01q6k-dfl-clu-000004",
			"first_name": "Nathan",
			"last_name": "Ngoumou Minpole",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "146",
			"external_uid": "dfl-obj-j01klb-dfl-clu-000008",
			"first_name": "Jonas",
			"last_name": "Nickisch",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "189",
			"external_uid": "dfl-obj-0027ak-dfl-clu-000004",
			"first_name": "Moritz",
			"last_name": "Nicolas",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "54",
			"external_uid": "dfl-obj-002gpz-dfl-clu-00000e",
			"first_name": "Justin Gideon",
			"last_name": "Njinmah",
			"team_code": "SVW",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "601",
			"external_uid": "dfl-obj-002g3d-dfl-clu-000017",
			"first_name": "Christopher Alan",
			"last_name": "Nkunku",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "360",
			"external_uid": "dfl-obj-002g51-dfl-clu-000003",
			"first_name": "Lukas Okechukwu",
			"last_name": "Nmecha",
			"team_code": "WOB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "230",
			"external_uid": "dfl-obj-j01bsn-dfl-clu-000007",
			"first_name": "Felix Kalu",
			"last_name": "Nmecha",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "467",
			"external_uid": "dfl-obj-002gpg-dfl-clu-000002",
			"first_name": "Nahuel Nicolas",
			"last_name": "Noll",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "579",
			"external_uid": "dfl-obj-002g6e-dfl-clu-000004",
			"first_name": "Conor",
			"last_name": "Noß",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "521",
			"external_uid": "dfl-obj-002gf3-dfl-clu-000017",
			"first_name": "Hugo",
			"last_name": "Novoa Ramos",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "460",
			"external_uid": "dfl-obj-j019uo-dfl-clu-000002",
			"first_name": "Stanley Pierre",
			"last_name": "Nsoki",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "94",
			"external_uid": "dfl-obj-0002hc-dfl-clu-00000d",
			"first_name": "Alexander",
			"last_name": "Nübel",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "455",
			"external_uid": "dfl-obj-0028r2-dfl-clu-000002",
			"first_name": "Kasim Adams",
			"last_name": "Nuhu",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "316",
			"external_uid": "dfl-obj-002fyc-dfl-clu-000016",
			"first_name": "Fabian",
			"last_name": "Nürnberger",
			"team_code": "SVD",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "361",
			"external_uid": "dfl-obj-0028cm-dfl-clu-000003",
			"first_name": "Bote Ridle",
			"last_name": "Nzuzi Baku",
			"team_code": "WOB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "680",
			"external_uid": "dfl-obj-j01kaf-dfl-clu-000003",
			"first_name": "David Uyoyo",
			"last_name": "Odogu",
			"team_code": "WOB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "542",
			"external_uid": "dfl-obj-j01pem-dfl-clu-00000s",
			"first_name": "Tim",
			"last_name": "Oermann",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "383",
			"external_uid": "dfl-obj-002fvn-dfl-clu-000010",
			"first_name": "Masaya",
			"last_name": "Okugawa",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "147",
			"external_uid": "dfl-obj-j01lj2-dfl-clu-000008",
			"first_name": "Mathias Flaga",
			"last_name": "Olesen",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "378",
			"external_uid": "dfl-obj-j01x7c-dfl-clu-000003",
			"first_name": "Rogério",
			"last_name": "Oliveira da Silva",
			"team_code": "WOB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "513",
			"external_uid": "dfl-obj-002gad-dfl-clu-000017",
			"first_name": "Daniel",
			"last_name": "Olmo Carvajal",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "194",
			"external_uid": "dfl-obj-002fv1-dfl-clu-000004",
			"first_name": "Jan Jakob",
			"last_name": "Olschowsky",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "188",
			"external_uid": "dfl-obj-j013ro-dfl-clu-000004",
			"first_name": "Jonas",
			"last_name": "Omlin",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "553",
			"external_uid": "dfl-obj-0027ls-dfl-clu-00000f",
			"first_name": "Jérôme Junior",
			"last_name": "Onguéné",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "332",
			"external_uid": "dfl-obj-0026tf-dfl-clu-000006",
			"first_name": "Karim",
			"last_name": "Onisiwo",
			"team_code": "M05",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "518",
			"external_uid": "dfl-obj-j01b7l-dfl-clu-000017",
			"first_name": "Ikoma Loïs",
			"last_name": "Openda",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "55",
			"external_uid": "dfl-obj-j01mrx-dfl-clu-00000e",
			"first_name": "Leon",
			"last_name": "Opitz",
			"team_code": "SVW",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "506",
			"external_uid": "dfl-obj-0000ho-dfl-clu-000017",
			"first_name": "Willi Thomas",
			"last_name": "Orban",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "250",
			"external_uid": "dfl-obj-j014f7-dfl-clu-00000s",
			"first_name": "Ivan",
			"last_name": "Ordets",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "259",
			"external_uid": "dfl-obj-002g24-dfl-clu-00000s",
			"first_name": "Jordi Martin Emmanuel",
			"last_name": "Osei-Tutu",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "265",
			"external_uid": "dfl-obj-002g4g-dfl-clu-00000s",
			"first_name": "Patrick",
			"last_name": "Osterhage",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "234",
			"external_uid": "dfl-obj-002ghp-dfl-clu-000007",
			"first_name": "Silas",
			"last_name": "Ostrzinski",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "394",
			"external_uid": "dfl-obj-0027ub-dfl-clu-000010",
			"first_name": "Reece Joel",
			"last_name": "Oxford",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "221",
			"external_uid": "dfl-obj-00278o-dfl-clu-000007",
			"first_name": "Salih",
			"last_name": "Özcan",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "651",
			"external_uid": "dfl-obj-0001ww-dfl-clu-00000v",
			"first_name": "Levin Mete",
			"last_name": "Öztunali",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "86",
			"external_uid": "dfl-obj-j01qzd-dfl-clu-00000f",
			"first_name": "Willian Joel",
			"last_name": "Pacho Tenorio",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "166",
			"external_uid": "dfl-obj-002g8h-dfl-clu-00000b",
			"first_name": "Exequiel Alejandro",
			"last_name": "Palacios",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "544",
			"external_uid": "dfl-obj-j01kwv-dfl-clu-000010",
			"first_name": "Renato",
			"last_name": "Palma Veiga",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "268",
			"external_uid": "dfl-obj-j01vx8-dfl-clu-00000s",
			"first_name": "Mats Henry",
			"last_name": "Pannewig",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "424",
			"external_uid": "dfl-obj-00285o-dfl-clu-00000v",
			"first_name": "Miloš",
			"last_name": "Pantović",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "225",
			"external_uid": "dfl-obj-j01a2e-dfl-clu-000007",
			"first_name": "Antonios",
			"last_name": "Papadopoulos",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "343",
			"external_uid": "dfl-obj-002g5c-dfl-clu-000006",
			"first_name": "Matondo-Merveille",
			"last_name": "Papela",
			"team_code": "M05",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "129",
			"external_uid": "dfl-obj-0002el-dfl-clu-000008",
			"first_name": "Leart",
			"last_name": "Paqarada",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "373",
			"external_uid": "dfl-obj-j01mru-dfl-clu-000003",
			"first_name": "Kevin Alexander",
			"last_name": "Paredes",
			"team_code": "WOB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "675",
			"external_uid": "dfl-obj-j01ie4-dfl-clu-000007",
			"first_name": "Marco",
			"last_name": "Pašalić",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "251",
			"external_uid": "dfl-obj-0026q5-dfl-clu-00000s",
			"first_name": "Felix",
			"last_name": "Passlack",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "15",
			"external_uid": "dfl-obj-0027g0-dfl-clu-00000g",
			"first_name": "Benjamin Jacques Marcel",
			"last_name": "Pavard",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "40",
			"external_uid": "dfl-obj-0027ue-dfl-clu-00000e",
			"first_name": "Jiří",
			"last_name": "Pavlenka",
			"team_code": "SVW",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "27",
			"external_uid": "dfl-obj-j01kah-dfl-clu-00000g",
			"first_name": "Aleksandar",
			"last_name": "Pavlovic",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "393",
			"external_uid": "dfl-obj-002g1w-dfl-clu-000010",
			"first_name": "Mads Giersing Valentin",
			"last_name": "Pedersen",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "132",
			"external_uid": "dfl-obj-0026zi-dfl-clu-000008",
			"first_name": "Kristian Majdahl",
			"last_name": "Pedersen",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "372",
			"external_uid": "dfl-obj-j01keb-dfl-clu-000003",
			"first_name": "Dženan",
			"last_name": "Pejčinović",
			"team_code": "WOB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "121",
			"external_uid": "dfl-obj-0027ug-dfl-clu-000008",
			"first_name": "Philipp",
			"last_name": "Pentke",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "531",
			"external_uid": "dfl-obj-j012d8-dfl-clu-00000b",
			"first_name": "Patrick",
			"last_name": "Pentz",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "555",
			"external_uid": "dfl-obj-j01pjc-dfl-clu-00000d",
			"first_name": "Juan José",
			"last_name": "Perea Mendoza",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "351",
			"external_uid": "dfl-obj-0028ou-dfl-clu-000003",
			"first_name": "Pavao",
			"last_name": "Pervan",
			"team_code": "WOB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "633",
			"external_uid": "dfl-obj-0000iu-dfl-clu-00000a",
			"first_name": "Nils",
			"last_name": "Petersen",
			"team_code": "SCF",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "312",
			"external_uid": "dfl-obj-002ge3-dfl-clu-000016",
			"first_name": "Luca",
			"last_name": "Pfeiffer",
			"team_code": "SVD",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "397",
			"external_uid": "dfl-obj-0028g3-dfl-clu-000010",
			"first_name": "Patric",
			"last_name": "Pfeiffer",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "277",
			"external_uid": "dfl-obj-0002b9-dfl-clu-00000a",
			"first_name": "Maximilian Marcus",
			"last_name": "Philipp",
			"team_code": "SCF",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "469",
			"external_uid": "dfl-obj-002gdm-dfl-clu-000002",
			"first_name": "Luca Dante",
			"last_name": "Philipp",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "484",
			"external_uid": "dfl-obj-0026kb-dfl-clu-000018",
			"first_name": "Florian",
			"last_name": "Pick",
			"team_code": "HDH",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "50",
			"external_uid": "dfl-obj-002fw5-dfl-clu-00000e",
			"first_name": "Amos",
			"last_name": "Pieper",
			"team_code": "SVW",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "491",
			"external_uid": "dfl-obj-0028t0-dfl-clu-000018",
			"first_name": "Marvin",
			"last_name": "Pieringer",
			"team_code": "HDH",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "183",
			"external_uid": "dfl-obj-0028qk-dfl-clu-000004",
			"first_name": "Alassane Alexandre",
			"last_name": "Pléa",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "612",
			"external_uid": "dfl-obj-000252-dfl-clu-00000b",
			"first_name": "Joel",
			"last_name": "Pohjanpalo",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "233",
			"external_uid": "dfl-obj-002gnf-dfl-clu-000007",
			"first_name": "Ole",
			"last_name": "Pohlmann",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "311",
			"external_uid": "dfl-obj-002gng-dfl-clu-000016",
			"first_name": "Thomas Mattias",
			"last_name": "Poppler Isherwood",
			"team_code": "SVD",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "508",
			"external_uid": "dfl-obj-0002f6-dfl-clu-000017",
			"first_name": "Yussuf Yurary",
			"last_name": "Poulsen",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "572",
			"external_uid": "dfl-obj-j01pz0-dfl-clu-000017",
			"first_name": "Oskar",
			"last_name": "Preil",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "452",
			"external_uid": "dfl-obj-0025j3-dfl-clu-000002",
			"first_name": "Grischa",
			"last_name": "Prömel",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "175",
			"external_uid": "dfl-obj-j01tj6-dfl-clu-00000b",
			"first_name": "Gustavo Adolfo",
			"last_name": "Puerta Molano",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "498",
			"external_uid": "dfl-obj-002gbn-dfl-clu-000018",
			"first_name": "Elidon",
			"last_name": "Qenaj",
			"team_code": "HDH",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "652",
			"external_uid": "dfl-obj-j01ead-dfl-clu-000002",
			"first_name": "Eduardo Filipe",
			"last_name": "Quaresma Vieira Coimbra Simoes",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "684",
			"external_uid": "dfl-obj-j01mc2-dfl-clu-000002",
			"first_name": "Joshua",
			"last_name": "Quarshie",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "554",
			"external_uid": "dfl-obj-002fy2-dfl-clu-00000f",
			"first_name": "Diant",
			"last_name": "Ramaj",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "494",
			"external_uid": "dfl-obj-002gcn-dfl-clu-000018",
			"first_name": "Melvin Aladin",
			"last_name": "Ramusović",
			"team_code": "HDH",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "196",
			"external_uid": "dfl-obj-j01cnt-dfl-clu-000004",
			"first_name": "Grant-Leon",
			"last_name": "Ranos",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "41",
			"external_uid": "dfl-obj-00259y-dfl-clu-00000e",
			"first_name": "Nicolai",
			"last_name": "Rapp",
			"team_code": "SVW",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "515",
			"external_uid": "dfl-obj-0027it-dfl-clu-000017",
			"first_name": "David",
			"last_name": "Raum",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "667",
			"external_uid": "dfl-obj-j01ec4-dfl-clu-00000d",
			"first_name": "Leon",
			"last_name": "Reichardt",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "199",
			"external_uid": "dfl-obj-002gc0-dfl-clu-000004",
			"first_name": "Rocco",
			"last_name": "Reitz",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "212",
			"external_uid": "dfl-obj-0000o3-dfl-clu-000007",
			"first_name": "Marco",
			"last_name": "Reus",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "395",
			"external_uid": "dfl-obj-0027uo-dfl-clu-000010",
			"first_name": "Elvis",
			"last_name": "Rexhbecaj",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "232",
			"external_uid": "dfl-obj-002g4x-dfl-clu-000007",
			"first_name": "Giovanni Alejandro",
			"last_name": "Reyna",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "318",
			"external_uid": "dfl-obj-j01dtm-dfl-clu-000016",
			"first_name": "Clemens",
			"last_name": "Riedel",
			"team_code": "SVD",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "241",
			"external_uid": "dfl-obj-0001i0-dfl-clu-00000s",
			"first_name": "Manuel",
			"last_name": "Riemann-Lorenzen",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "344",
			"external_uid": "dfl-obj-j01cx5-dfl-clu-000006",
			"first_name": "Lasse Finn",
			"last_name": "Rieß",
			"team_code": "M05",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "420",
			"external_uid": "dfl-obj-0028p2-dfl-clu-00000v",
			"first_name": "Frederik",
			"last_name": "Riis Rønnow",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "60",
			"external_uid": "dfl-obj-000024-dfl-clu-00000f",
			"first_name": "Sebastian",
			"last_name": "Rode",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "292",
			"external_uid": "dfl-obj-002gqb-dfl-clu-00000a",
			"first_name": "Merlin",
			"last_name": "Röhl",
			"team_code": "SCF",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "589",
			"external_uid": "dfl-obj-002gb8-dfl-clu-000008",
			"first_name": "Julian",
			"last_name": "Roloff",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "263",
			"external_uid": "dfl-obj-002fwh-dfl-clu-00000s",
			"first_name": "Moritz",
			"last_name": "Römling",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "308",
			"external_uid": "dfl-obj-002705-dfl-clu-000016",
			"first_name": "Frank",
			"last_name": "Ronstadt",
			"team_code": "SVD",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "606",
			"external_uid": "dfl-obj-0027xx-dfl-clu-000003",
			"first_name": "Paulo Otávio",
			"last_name": "Rosa da Silva",
			"team_code": "WOB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "288",
			"external_uid": "dfl-obj-j01d2o-dfl-clu-00000a",
			"first_name": "Maximilian",
			"last_name": "Rosenfelder",
			"team_code": "SCF",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "642",
			"external_uid": "dfl-obj-j01kai-dfl-clu-000007",
			"first_name": "Tom Alexander",
			"last_name": "Rothe",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "428",
			"external_uid": "dfl-obj-0028sa-dfl-clu-00000v",
			"first_name": "Jérôme",
			"last_name": "Roussillon",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "630",
			"external_uid": "dfl-obj-0000vq-dfl-clu-000002",
			"first_name": "Sebastian",
			"last_name": "Rudy",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "224",
			"external_uid": "dfl-obj-0028rm-dfl-clu-000007",
			"first_name": "Julian",
			"last_name": "Ryerson",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "214",
			"external_uid": "dfl-obj-002666-dfl-clu-000007",
			"first_name": "Marcel",
			"last_name": "Sabitzer",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "53",
			"external_uid": "dfl-obj-j01dwg-dfl-clu-00000e",
			"first_name": "Dikeni-Rafid",
			"last_name": "Salifou",
			"team_code": "SVW",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "282",
			"external_uid": "dfl-obj-002fvj-dfl-clu-00000a",
			"first_name": "Roland",
			"last_name": "Sallai",
			"team_code": "SCF",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "453",
			"external_uid": "dfl-obj-002g5w-dfl-clu-000002",
			"first_name": "Diadié",
			"last_name": "Samassékou",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "610",
			"external_uid": "dfl-obj-0028os-dfl-clu-00000b",
			"first_name": "Paulo Henrique",
			"last_name": "Sampaio Filho",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "11",
			"external_uid": "dfl-obj-0002au-dfl-clu-00000g",
			"first_name": "Leroy Aziz",
			"last_name": "Sané",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "116",
			"external_uid": "dfl-obj-002gpq-dfl-clu-00000d",
			"first_name": "Mohamed",
			"last_name": "Sankoh",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "598",
			"external_uid": "dfl-obj-j01ks5-dfl-clu-00000v",
			"first_name": "Hassan-Malick",
			"last_name": "Sanogo",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "66",
			"external_uid": "dfl-obj-j017kt-dfl-clu-00000f",
			"first_name": "Rafael",
			"last_name": "Santos Borré Maury",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "387",
			"external_uid": "dfl-obj-0026wo-dfl-clu-000010",
			"first_name": "Noah Joel",
			"last_name": "Sarenren Bazee",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "14",
			"external_uid": "dfl-obj-002gm4-dfl-clu-00000g",
			"first_name": "Bouna Junior",
			"last_name": "Sarr",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "201",
			"external_uid": "dfl-obj-002gnn-dfl-clu-000004",
			"first_name": "Joseph Michael",
			"last_name": "Scally",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "441",
			"external_uid": "dfl-obj-j01gua-dfl-clu-00000v",
			"first_name": "András",
			"last_name": "Schäfer",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "564",
			"external_uid": "dfl-obj-j01cnb-dfl-clu-00000g",
			"first_name": "Johannes",
			"last_name": "Schenk",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "159",
			"external_uid": "dfl-obj-002g6u-dfl-clu-00000b",
			"first_name": "Patrik",
			"last_name": "Schick",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "485",
			"external_uid": "dfl-obj-002g56-dfl-clu-000018",
			"first_name": "Stefan",
			"last_name": "Schimmer",
			"team_code": "HDH",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "637",
			"external_uid": "dfl-obj-0027v2-dfl-clu-000008",
			"first_name": "Kingsley",
			"last_name": "Schindler",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "514",
			"external_uid": "dfl-obj-002g1u-dfl-clu-000017",
			"first_name": "Xaver",
			"last_name": "Schlager",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "527",
			"external_uid": "dfl-obj-j01kau-dfl-clu-000017",
			"first_name": "Timo",
			"last_name": "Schlieck",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "283",
			"external_uid": "dfl-obj-0028d6-dfl-clu-00000a",
			"first_name": "Keven",
			"last_name": "Schlotterbeck",
			"team_code": "SCF",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "227",
			"external_uid": "dfl-obj-0028t1-dfl-clu-000007",
			"first_name": "Nico Cedric",
			"last_name": "Schlotterbeck",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "45",
			"external_uid": "dfl-obj-002fx2-dfl-clu-00000e",
			"first_name": "Romano Christian",
			"last_name": "Schmid",
			"team_code": "SVW",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "644",
			"external_uid": "dfl-obj-000012-dfl-clu-00000a",
			"first_name": "Jonathan",
			"last_name": "Schmid",
			"team_code": "SCF",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "591",
			"external_uid": "dfl-obj-j01pzl-dfl-clu-000008",
			"first_name": "Maximilian",
			"last_name": "Schmid",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "551",
			"external_uid": "dfl-obj-0027hg-dfl-clu-00000e",
			"first_name": "Niklas Uwe",
			"last_name": "Schmidt",
			"team_code": "SVW",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "128",
			"external_uid": "dfl-obj-0002bo-dfl-clu-000008",
			"first_name": "Benno",
			"last_name": "Schmitz",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "299",
			"external_uid": "dfl-obj-0000zp-dfl-clu-000016",
			"first_name": "Fabian",
			"last_name": "Schnellhardt",
			"team_code": "SVD",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "112",
			"external_uid": "dfl-obj-j01dke-dfl-clu-00000d",
			"first_name": "Florian",
			"last_name": "Schock",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "493",
			"external_uid": "dfl-obj-002ga9-dfl-clu-000018",
			"first_name": "Jan",
			"last_name": "Schöppner",
			"team_code": "HDH",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "665",
			"external_uid": "dfl-obj-j01cup-dfl-clu-00000f",
			"first_name": "Jan Alex Wilson",
			"last_name": "Schroeder",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "300",
			"external_uid": "dfl-obj-00018w-dfl-clu-000016",
			"first_name": "Marcel",
			"last_name": "Schuhen",
			"team_code": "SVD",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "576",
			"external_uid": "dfl-obj-0000se-dfl-clu-000007",
			"first_name": "Nico",
			"last_name": "Schulz",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "567",
			"external_uid": "dfl-obj-j01kej-dfl-clu-000006",
			"first_name": "Philipp",
			"last_name": "Schulz",
			"team_code": "M05",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "365",
			"external_uid": "dfl-obj-j01d3f-dfl-clu-000003",
			"first_name": "Philipp",
			"last_name": "Schulze",
			"team_code": "WOB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "126",
			"external_uid": "dfl-obj-0002he-dfl-clu-000008",
			"first_name": "Marvin",
			"last_name": "Schwäbe",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "668",
			"external_uid": "dfl-obj-j01anl-dfl-clu-000008",
			"first_name": "Joshua",
			"last_name": "Schwirten",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "414",
			"external_uid": "dfl-obj-00001d-dfl-clu-00000v",
			"first_name": "Alexander",
			"last_name": "Schwolow",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "636",
			"external_uid": "dfl-obj-00028y-dfl-clu-00000v",
			"first_name": "Paul",
			"last_name": "Seguin",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "119",
			"external_uid": "dfl-obj-j01kdy-dfl-clu-00000d",
			"first_name": "Dennis",
			"last_name": "Seimen",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "522",
			"external_uid": "dfl-obj-j01fo9-dfl-clu-000017",
			"first_name": "Nicolas",
			"last_name": "Seiwald",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "125",
			"external_uid": "dfl-obj-000270-dfl-clu-000008",
			"first_name": "Davie",
			"last_name": "Selke",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "623",
			"external_uid": "dfl-obj-j01jm7-dfl-clu-00000b",
			"first_name": "Batuhan Zidan",
			"last_name": "Sertdemir",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "528",
			"external_uid": "dfl-obj-j01kdp-dfl-clu-000017",
			"first_name": "Benjamin",
			"last_name": "Šeško",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "488",
			"external_uid": "dfl-obj-0028li-dfl-clu-000018",
			"first_name": "Kevin Simone",
			"last_name": "Sessa",
			"team_code": "HDH",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "307",
			"external_uid": "dfl-obj-0027j5-dfl-clu-000016",
			"first_name": "Aaron",
			"last_name": "Seydel",
			"team_code": "SVD",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "346",
			"external_uid": "dfl-obj-j01dsy-dfl-clu-000006",
			"first_name": "Eniss",
			"last_name": "Shabani",
			"team_code": "M05",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "429",
			"external_uid": "dfl-obj-j01bje-dfl-clu-00000v",
			"first_name": "Theoson-Jordan",
			"last_name": "Siebatcheu",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "495",
			"external_uid": "dfl-obj-002g76-dfl-clu-000018",
			"first_name": "Tim",
			"last_name": "Siersleben",
			"team_code": "HDH",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "294",
			"external_uid": "dfl-obj-002gl1-dfl-clu-00000a",
			"first_name": "Kiliann Eric",
			"last_name": "Sildillia",
			"team_code": "SCF",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "69",
			"external_uid": "dfl-obj-002fxs-dfl-clu-00000f",
			"first_name": "Lucas",
			"last_name": "Silva Melo",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "519",
			"external_uid": "dfl-obj-j01cy1-dfl-clu-000017",
			"first_name": "Mohamed",
			"last_name": "Simakan",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "87",
			"external_uid": "dfl-obj-j01sau-dfl-clu-00000f",
			"first_name": "Simon",
			"last_name": "Simoni",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "526",
			"external_uid": "dfl-obj-j01k71-dfl-clu-000017",
			"first_name": "Xavier Quentin Shay",
			"last_name": "Simons",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "641",
			"external_uid": "dfl-obj-002g1p-dfl-clu-00000b",
			"first_name": "Daley",
			"last_name": "Sinkgraven",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "179",
			"external_uid": "dfl-obj-0000ht-dfl-clu-000004",
			"first_name": "Tobias",
			"last_name": "Sippel",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "421",
			"external_uid": "dfl-obj-002659-dfl-clu-00000v",
			"first_name": "Tim",
			"last_name": "Skarke",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "68",
			"external_uid": "dfl-obj-002g4a-dfl-clu-00000f",
			"first_name": "Ellyes Joris",
			"last_name": "Skhiri",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "458",
			"external_uid": "dfl-obj-002g4b-dfl-clu-000002",
			"first_name": "Robert Faxe",
			"last_name": "Skov",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "592",
			"external_uid": "dfl-obj-j01q2z-dfl-clu-000008",
			"first_name": "Rijad",
			"last_name": "Smajic",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "81",
			"external_uid": "dfl-obj-j01g1a-dfl-clu-00000f",
			"first_name": "Hrvoje",
			"last_name": "Smolčić",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "248",
			"external_uid": "dfl-obj-0001wb-dfl-clu-00000s",
			"first_name": "Danilo Teodoro",
			"last_name": "Soares",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "534",
			"external_uid": "dfl-obj-j01q7l-dfl-clu-000008",
			"first_name": "Nikola",
			"last_name": "Soldo",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "547",
			"external_uid": "dfl-obj-0002dr-dfl-clu-00000g",
			"first_name": "Yann",
			"last_name": "Sommer",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "571",
			"external_uid": "dfl-obj-002glk-dfl-clu-000017",
			"first_name": "Alexander",
			"last_name": "Sørloth",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "99",
			"external_uid": "dfl-obj-0028pv-dfl-clu-00000d",
			"first_name": "Borna",
			"last_name": "Sosa",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "552",
			"external_uid": "dfl-obj-0026jw-dfl-clu-00000f",
			"first_name": "Mohameth Djibril Ibrahima",
			"last_name": "Sow",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "347",
			"external_uid": "dfl-obj-002ge5-dfl-clu-000006",
			"first_name": "Anton Levi",
			"last_name": "Stach",
			"team_code": "M05",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "632",
			"external_uid": "dfl-obj-0001um-dfl-clu-00000s",
			"first_name": "Konstantinos 'Kostas'",
			"last_name": "Stafylidis",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "51",
			"external_uid": "dfl-obj-j01c07-dfl-clu-00000e",
			"first_name": "Jens Dalsgaard",
			"last_name": "Stage",
			"team_code": "SVW",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "398",
			"external_uid": "dfl-obj-0028qe-dfl-clu-000010",
			"first_name": "Jozo",
			"last_name": "Stanić",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "163",
			"external_uid": "dfl-obj-002gic-dfl-clu-00000b",
			"first_name": "Josip",
			"last_name": "Stanišić",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "37",
			"external_uid": "dfl-obj-0001hj-dfl-clu-00000e",
			"first_name": "Niklas",
			"last_name": "Stark",
			"team_code": "SVW",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "442",
			"external_uid": "dfl-obj-j01k6n-dfl-clu-00000v",
			"first_name": "Yannic",
			"last_name": "Stein",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "91",
			"external_uid": "dfl-obj-0026ma-dfl-clu-00000d",
			"first_name": "Pascal",
			"last_name": "Stenzel",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "464",
			"external_uid": "dfl-obj-002gcq-dfl-clu-000002",
			"first_name": "Angelo",
			"last_name": "Stiller",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "624",
			"external_uid": "dfl-obj-0000sp-dfl-clu-000004",
			"first_name": "Lars Edi",
			"last_name": "Stindl",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "245",
			"external_uid": "dfl-obj-000172-dfl-clu-00000s",
			"first_name": "Kevin",
			"last_name": "Stöger",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "325",
			"external_uid": "dfl-obj-j01rhj-dfl-clu-000016",
			"first_name": "Filip",
			"last_name": "Stojilkovic",
			"team_code": "SVD",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "588",
			"external_uid": "dfl-obj-002gl2-dfl-clu-000008",
			"first_name": "Georg",
			"last_name": "Strauch",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "658",
			"external_uid": "dfl-obj-0000zv-dfl-clu-000010",
			"first_name": "Tobias",
			"last_name": "Strobl",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "217",
			"external_uid": "dfl-obj-0001ht-dfl-clu-000007",
			"first_name": "Niklas",
			"last_name": "Süle",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "357",
			"external_uid": "dfl-obj-j0165w-dfl-clu-000003",
			"first_name": "Mattias Olof",
			"last_name": "Svanberg",
			"team_code": "WOB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "459",
			"external_uid": "dfl-obj-j019k9-dfl-clu-000002",
			"first_name": "Attila Árpád",
			"last_name": "Szalai",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "602",
			"external_uid": "dfl-obj-002gna-dfl-clu-000017",
			"first_name": "Dominik",
			"last_name": "Szoboszlai",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "156",
			"external_uid": "dfl-obj-0001up-dfl-clu-00000b",
			"first_name": "Jonathan Glao",
			"last_name": "Tah",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "165",
			"external_uid": "dfl-obj-002gar-dfl-clu-00000b",
			"first_name": "Edmond Fayçal",
			"last_name": "Tapsoba",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "29",
			"external_uid": "dfl-obj-j01n65-dfl-clu-00000g",
			"first_name": "Mathys Henri",
			"last_name": "Tel",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "210",
			"external_uid": "dfl-obj-j01rju-dfl-clu-000004",
			"first_name": "Semir",
			"last_name": "Telalovic",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "473",
			"external_uid": "dfl-obj-00009k-dfl-clu-000018",
			"first_name": "Norman",
			"last_name": "Theuerkauf",
			"team_code": "HDH",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "262",
			"external_uid": "dfl-obj-0028t4-dfl-clu-00000s",
			"first_name": "Niclas",
			"last_name": "Thiede",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "136",
			"external_uid": "dfl-obj-002g89-dfl-clu-000008",
			"first_name": "Jan Uwe",
			"last_name": "Thielmann",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "474",
			"external_uid": "dfl-obj-0000vv-dfl-clu-000018",
			"first_name": "Denis",
			"last_name": "Thomalla",
			"team_code": "HDH",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "561",
			"external_uid": "dfl-obj-j019xr-dfl-clu-00000v",
			"first_name": "Morten",
			"last_name": "Thorsby",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "603",
			"external_uid": "dfl-obj-002g3i-dfl-clu-000004",
			"first_name": "Marcus Lilian",
			"last_name": "Thuram-Ulien",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "385",
			"external_uid": "dfl-obj-0026vb-dfl-clu-000010",
			"first_name": "Phillip",
			"last_name": "Tietz",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "130",
			"external_uid": "dfl-obj-002gnb-dfl-clu-000008",
			"first_name": "Steffen",
			"last_name": "Tigges",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "537",
			"external_uid": "dfl-obj-002gcu-dfl-clu-00000g",
			"first_name": "Malik Leon",
			"last_name": "Tillman",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "468",
			"external_uid": "dfl-obj-j01d03-dfl-clu-000002",
			"first_name": "Umut Deger",
			"last_name": "Tohumcu",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "267",
			"external_uid": "dfl-obj-j01ks4-dfl-clu-00000s",
			"first_name": "Mohammed",
			"last_name": "Tolba",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "324",
			"external_uid": "dfl-obj-j01pry-dfl-clu-000016",
			"first_name": "Fabio",
			"last_name": "Torsiello",
			"team_code": "SVD",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "631",
			"external_uid": "dfl-obj-002fxu-dfl-clu-00000f",
			"first_name": "Almamy",
			"last_name": "Touré",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "423",
			"external_uid": "dfl-obj-002gdg-dfl-clu-00000v",
			"first_name": "Lucas Simon Pierre",
			"last_name": "Tousart",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "490",
			"external_uid": "dfl-obj-j01azq-dfl-clu-000018",
			"first_name": "Omar Haktab",
			"last_name": "Traoré",
			"team_code": "HDH",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "59",
			"external_uid": "dfl-obj-0000h0-dfl-clu-00000f",
			"first_name": "Kevin Christian",
			"last_name": "Trapp",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "412",
			"external_uid": "dfl-obj-0002fx-dfl-clu-00000v",
			"first_name": "Christopher",
			"last_name": "Trimmel",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "499",
			"external_uid": "dfl-obj-j01i8o-dfl-clu-000018",
			"first_name": "Paul",
			"last_name": "Tschernuth",
			"team_code": "HDH",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "386",
			"external_uid": "dfl-obj-0026tt-dfl-clu-000010",
			"first_name": "Ohis Felix",
			"last_name": "Uduokhai",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "449",
			"external_uid": "dfl-obj-0001lz-dfl-clu-000002",
			"first_name": "Kevin John",
			"last_name": "Ufuoma Akpoguma",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "205",
			"external_uid": "dfl-obj-j01kal-dfl-clu-000004",
			"first_name": "Lukas",
			"last_name": "Ullrich",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "2",
			"external_uid": "dfl-obj-0000lt-dfl-clu-00000g",
			"first_name": "Sven",
			"last_name": "Ulreich",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "115",
			"external_uid": "dfl-obj-j01ec7-dfl-clu-00000d",
			"first_name": "Laurin",
			"last_name": "Ulrich",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "645",
			"external_uid": "dfl-obj-002fvz-dfl-clu-000007",
			"first_name": "Luca",
			"last_name": "Unbehaun",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "103",
			"external_uid": "dfl-obj-0027yk-dfl-clu-00000d",
			"first_name": "Deniz",
			"last_name": "Undav",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "13",
			"external_uid": "dfl-obj-0027kl-dfl-clu-00000g",
			"first_name": "Dayotchanculle Oswald",
			"last_name": "Upamecano",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "272",
			"external_uid": "dfl-obj-0000xk-dfl-clu-00000a",
			"first_name": "Benjamin",
			"last_name": "Uphoff",
			"team_code": "SCF",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "687",
			"external_uid": "dfl-obj-j01jm5-dfl-clu-000008",
			"first_name": "Jonas Kurt",
			"last_name": "Urbig",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "123",
			"external_uid": "dfl-obj-0000mn-dfl-clu-000008",
			"first_name": "Mark-Alexander",
			"last_name": "Uth",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "107",
			"external_uid": "dfl-obj-0028ls-dfl-clu-00000d",
			"first_name": "Josha Mamadou Karaboue",
			"last_name": "Vagnoman",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "562",
			"external_uid": "dfl-obj-002g6r-dfl-clu-000017",
			"first_name": "André Miguel",
			"last_name": "Valente da Silva",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "545",
			"external_uid": "dfl-obj-j01khm-dfl-clu-000003",
			"first_name": "Micky",
			"last_name": "van de Ven",
			"team_code": "WOB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "342",
			"external_uid": "dfl-obj-j01crj-dfl-clu-000006",
			"first_name": "Sepp",
			"last_name": "van den Berg",
			"team_code": "M05",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "391",
			"external_uid": "dfl-obj-002g1x-dfl-clu-000010",
			"first_name": "Rubén Estephan",
			"last_name": "Vargas Martínez",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "38",
			"external_uid": "dfl-obj-0026ub-dfl-clu-00000e",
			"first_name": "Miloš",
			"last_name": "Veljković",
			"team_code": "SVW",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "24",
			"external_uid": "dfl-obj-j01cnv-dfl-clu-00000g",
			"first_name": "Gabriel",
			"last_name": "Vidović",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "327",
			"external_uid": "dfl-obj-0000nb-dfl-clu-000006",
			"first_name": "Danny",
			"last_name": "Vieira da Costa",
			"team_code": "M05",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "323",
			"external_uid": "dfl-obj-j01plr-dfl-clu-000016",
			"first_name": "Oscar Ingemar Kristoffer",
			"last_name": "Vilhelmsson",
			"team_code": "SVD",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "445",
			"external_uid": "dfl-obj-000040-dfl-clu-000002",
			"first_name": "Kevin",
			"last_name": "Vogt",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "413",
			"external_uid": "dfl-obj-00007t-dfl-clu-00000v",
			"first_name": "Kevin",
			"last_name": "Volland",
			"team_code": "FCU",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "370",
			"external_uid": "dfl-obj-j01i6l-dfl-clu-000003",
			"first_name": "Aster Jan",
			"last_name": "Vranckx",
			"team_code": "WOB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "634",
			"external_uid": "dfl-obj-j01d33-dfl-clu-00000a",
			"first_name": "Robert",
			"last_name": "Wagner",
			"team_code": "SCF",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "206",
			"external_uid": "dfl-obj-j01kek-dfl-clu-000004",
			"first_name": "Simon",
			"last_name": "Walde",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "127",
			"external_uid": "dfl-obj-00021q-dfl-clu-000008",
			"first_name": "Gian-Luca",
			"last_name": "Waldschmidt",
			"team_code": "KOE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "28",
			"external_uid": "dfl-obj-j01kel-dfl-clu-00000g",
			"first_name": "Paul",
			"last_name": "Wanner",
			"team_code": "FCB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "456",
			"external_uid": "dfl-obj-0028pl-dfl-clu-000002",
			"first_name": "Wout François Maria",
			"last_name": "Weghorst",
			"team_code": "TSG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "185",
			"external_uid": "dfl-obj-00029n-dfl-clu-000004",
			"first_name": "Julian",
			"last_name": "Weigl",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "348",
			"external_uid": "dfl-obj-j01kem-dfl-clu-000006",
			"first_name": "Nelson Felix Patrick",
			"last_name": "Weiper",
			"team_code": "M05",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "34",
			"external_uid": "dfl-obj-0000yo-dfl-clu-00000e",
			"first_name": "Mitchell-Elijah",
			"last_name": "Weiser",
			"team_code": "SVW",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "291",
			"external_uid": "dfl-obj-002gl6-dfl-clu-00000a",
			"first_name": "Noah Raphael",
			"last_name": "Weißhaupt",
			"team_code": "SCF",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "70",
			"external_uid": "dfl-obj-j01cnw-dfl-clu-00000f",
			"first_name": "Marcel",
			"last_name": "Wenig",
			"team_code": "SGE",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "509",
			"external_uid": "dfl-obj-00021u-dfl-clu-000017",
			"first_name": "Timo",
			"last_name": "Werner",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "330",
			"external_uid": "dfl-obj-j011lz-dfl-clu-000006",
			"first_name": "Silvan Dominic",
			"last_name": "Widmer",
			"team_code": "M05",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "371",
			"external_uid": "dfl-obj-j01k33-dfl-clu-000003",
			"first_name": "Patrick",
			"last_name": "Wimmer",
			"team_code": "WOB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "362",
			"external_uid": "dfl-obj-j019ze-dfl-clu-000003",
			"first_name": "Jonas Older",
			"last_name": "Wind",
			"team_code": "WOB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "407",
			"external_uid": "dfl-obj-j01jm2-dfl-clu-000010",
			"first_name": "Frederik Franck",
			"last_name": "Winther",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "169",
			"external_uid": "dfl-obj-002gbk-dfl-clu-00000b",
			"first_name": "Florian Richard",
			"last_name": "Wirtz",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "249",
			"external_uid": "dfl-obj-0002if-dfl-clu-00000s",
			"first_name": "Maximilian",
			"last_name": "Wittek",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "190",
			"external_uid": "dfl-obj-j016rq-dfl-clu-000004",
			"first_name": "Maximilian",
			"last_name": "Wöber",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "220",
			"external_uid": "dfl-obj-0002ig-dfl-clu-000007",
			"first_name": "Marius",
			"last_name": "Wolf",
			"team_code": "BVB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "192",
			"external_uid": "dfl-obj-002g1l-dfl-clu-000004",
			"first_name": "Hannes",
			"last_name": "Wolf",
			"team_code": "BMG",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "52",
			"external_uid": "dfl-obj-002g7a-dfl-clu-00000e",
			"first_name": "Nick",
			"last_name": "Woltemade",
			"team_code": "SVW",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "152",
			"external_uid": "dfl-obj-000179-dfl-clu-00000b",
			"first_name": "Granit",
			"last_name": "Xhaka",
			"team_code": "B04",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "619",
			"external_uid": "dfl-obj-j01cgo-dfl-clu-000010",
			"first_name": "Kelvin Kwarteng",
			"last_name": "Yeboah",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "101",
			"external_uid": "dfl-obj-0027w7-dfl-clu-00000d",
			"first_name": "Dan-Axel",
			"last_name": "Zagadou",
			"team_code": "VFB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "408",
			"external_uid": "dfl-obj-j01kas-dfl-clu-000010",
			"first_name": "Aaron",
			"last_name": "Zehnter",
			"team_code": "FCA",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "333",
			"external_uid": "dfl-obj-0027wd-dfl-clu-000006",
			"first_name": "Robin",
			"last_name": "Zentner",
			"team_code": "M05",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "359",
			"external_uid": "dfl-obj-j017lg-dfl-clu-000003",
			"first_name": "Cédric Adrian",
			"last_name": "Zesiger",
			"team_code": "WOB",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "36",
			"external_uid": "dfl-obj-00259o-dfl-clu-00000e",
			"first_name": "Michael",
			"last_name": "Zetterer",
			"team_code": "SVW",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "301",
			"external_uid": "dfl-obj-j013f9-dfl-clu-000016",
			"first_name": "Christoph",
			"last_name": "Zimmermann",
			"team_code": "SVD",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "507",
			"external_uid": "dfl-obj-0002bb-dfl-clu-000017",
			"first_name": "Leopold",
			"last_name": "Zingerle",
			"team_code": "RBL",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		},
		{
			"id": "242",
			"external_uid": "dfl-obj-00002z-dfl-clu-00000s",
			"first_name": "Simon",
			"last_name": "Zoller",
			"team_code": "BOC",
			"bet_points": {
				"goal_or_assist": 3,
				"winning_team": 3
			}
		}
	],
	"teams": [
		{
			"uid": "1",
			"team_code": "HDH",
			"logo": "hdh",
			"name": "1. FC Heidenheim 1846"
		},
		{
			"uid": "2",
			"team_code": "KOE",
			"logo": "koe",
			"name": "1. FC Köln"
		},
		{
			"uid": "3",
			"team_code": "FCU",
			"logo": "fcu",
			"name": "1. FC Union Berlin"
		},
		{
			"uid": "4",
			"team_code": "M05",
			"logo": "m05",
			"name": "1. FSV Mainz 05"
		},
		{
			"uid": "5",
			"team_code": "B04",
			"logo": "b04",
			"name": "Bayer 04 Leverkusen"
		},
		{
			"uid": "6",
			"team_code": "BVB",
			"logo": "bvb",
			"name": "Borussia Dortmund"
		},
		{
			"uid": "7",
			"team_code": "BMG",
			"logo": "bmg",
			"name": "Borussia Mönchengladbach"
		},
		{
			"uid": "8",
			"team_code": "SGE",
			"logo": "sge",
			"name": "Eintracht Frankfurt"
		},
		{
			"uid": "9",
			"team_code": "FCA",
			"logo": "fca",
			"name": "FC Augsburg"
		},
		{
			"uid": "10",
			"team_code": "FCB",
			"logo": "fcb",
			"name": "FC Bayern München"
		},
		{
			"uid": "11",
			"team_code": "RBL",
			"logo": "rbl",
			"name": "RB Leipzig"
		},
		{
			"uid": "12",
			"team_code": "SCF",
			"logo": "scf",
			"name": "Sport-Club Freiburg"
		},
		{
			"uid": "13",
			"team_code": "SVD",
			"logo": "svd",
			"name": "SV Darmstadt 98"
		},
		{
			"uid": "14",
			"team_code": "SVW",
			"logo": "svw",
			"name": "SV Werder Bremen"
		},
		{
			"uid": "15",
			"team_code": "TSG",
			"logo": "tsg",
			"name": "TSG Hoffenheim"
		},
		{
			"uid": "16",
			"team_code": "VFB",
			"logo": "vfb",
			"name": "VfB Stuttgart"
		},
		{
			"uid": "17",
			"team_code": "BOC",
			"logo": "boc",
			"name": "VfL Bochum 1848"
		},
		{
			"uid": "18",
			"team_code": "WOB",
			"logo": "wob",
			"name": "VfL Wolfsburg"
		}
	],
	"config_version": "3e995f5ef1d3cce6d1f7534618f10c0c89dc65194be5c7be6bad5a738948b080"
}