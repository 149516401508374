import React from "react"
import Dropdown from "../Ui/Dropdown"
import {Block, Note} from "@root/pages/sandbox"
import { Buddy, Matchday } from "@root/types"

const users = [
	{id: 1, name: "Alice"},
	{id: 2, name: "Bob"},
	{id: 3, name: "Charlie"},
]
const MATCHDAYS: Matchday[] = [
  {
    id: 1,
    title: "Matchday 1",
    subtitle: "Opening Day",
    countdown_sec: 3600,
    state: "scored",
    bets:[]
  },
  {
    id: 2,
    title: "Matchday 2",
    subtitle: "The Derby",
    state: "in_progress",
    bets:[]
  },
  {
    id: 3,
    title: "Matchday 3",
    subtitle: "Sunday Special",
    state: "scored",
    bets:[]
  },
  {
    id: 4,
    title: "Matchday 4",
    subtitle: "Midweek Madness",
    state: "cancelled",
    cancelled_info: "Due to weather conditions",
    bets:[]
  }]

const FRIENDS: Buddy[] = [
	{
		user: {id: 1, label: "Alice"},
		has_new_story: true
	},
	{
		user: {id: 2, label: "Alex"},
		has_new_story: false
	}
]

const DropdownMock = () => {
	return (
		<>
			<Block>
				<Note> dropdown single</Note>
				<div style={{display: "flex", flexWrap:"wrap"}}>
				
				</div>
			</Block>
			<Block>
				<Note> dropdown two in a row </Note>
				<div style={{display: "flex"}}>
	
				</div>
			</Block>
      <Block>
				<Note> dropdown empty </Note>
				<div style={{display: "flex", width: "50px"}}>

				</div>
			</Block>
		</>
	)
}

export default DropdownMock
