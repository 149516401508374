import { Block, Note } from '@root/pages/sandbox'
import React from 'react'
import {Avatar, AvatarWithHalo} from './index'
import { User } from '@root/types'
import imageMock from "@images/bg.jpg"

const ENTRIES: User[]  =  [{ id: 1, label: "Alice", is_expert: true },{ id: 1, label: "Alice", is_expert: true },{ id: 1, label: "Alice", is_expert: true }]
const AvatarSendbox = () => {
  return (
    <>
      <Block>
        <Note> Leaderboard avatar no image</Note>
        <div className="neo__avatar__wrapper">
          <Avatar user={ENTRIES[0]} onAvatarClick={(user)=>console.log(user)}/>
        </div>
      </Block>
      <Block>
        <Note> Leaderboard avatar image</Note>
        <div className="neo__avatar__wrapper">
          <Avatar user={ENTRIES[1]} imageUrl={imageMock} onAvatarClick={(user)=>console.log(user)}/>
        </div>
      </Block>
      <Block>
        <Note> Leaderboard avatar with halo not seen</Note>
        <div className="neo__avatar__wrapper">
          <AvatarWithHalo user={ENTRIES[1]} imageUrl={imageMock} isSeen={false} onAvatarClick={(user)=>console.log(user)}/>
        </div>
      </Block>
      <Block>
        <Note> Leaderboard avatar with halo seen</Note>
        <div className="neo__avatar__wrapper">
          <AvatarWithHalo user={ENTRIES[1]} imageUrl={imageMock} isSeen={true} onAvatarClick={(user)=>console.log(user)}/>
        </div>    
      </Block>
    </>
  )
}

export default ["Avatar" , AvatarSendbox]