import React from "react"

import { PageTitle, SectionTitle, Block, Note } from '../../../pages/sandbox'
import _ from "lodash"
import { Row } from "../Ui"
import Button from "./Button"

const Sandbox:React.FC = () => {
  return <>
    <SectionTitle>Button</SectionTitle>
    <Block>
      <Note>primary, secondary, then both disabled</Note>
      <Row>
        <Button type="primary" onClick={() => console.log("click")}>Primary Button</Button>
        <Button type="secondary" onClick={() => console.log("click")}>Secondary Button</Button>
        <Button disabled type="primary" onClick={() => console.log("click")}>Primary disabled</Button>
        <Button disabled type="secondary" onClick={() => console.log("click")}>Secondary disabled</Button>
      </Row>
    </Block>
  </>
}

export default ["ui", Sandbox]