import { Tab } from "@root/types"
import React, { memo, useState } from "react"

type TabComponentProps = {
  tab: Tab
  isActive: boolean
  onClick: () => void
  disabled: boolean
}

const TabComponent: React.FC<TabComponentProps> = ({ tab, isActive, onClick, disabled }) => {

  const tabStyle = ["neo__nav__tab",
    disabled ? "neo__nav__tab--disabled" : "",
    isActive ? "neo__nav__tab--active" : ""
  ].join(" ")
 return (
  <div 
    role="tab"
    key={tab}
    className={tabStyle} 
    onClick={onClick}
  >
    <span>{tab}</span>
  </div>
  )

}

type NavBarProps = {
  tabs: Tab[]
  onSelectTab: (tab: Tab) => void
  activeTab?: Tab
  disabled? : boolean 
}

const NavBar: React.FC<NavBarProps> = memo(({tabs,onSelectTab,activeTab,disabled}) => {
  /*const [activeTab, setActiveTab] = useState<Tab>(initialTab || "picks")
  const handleTabClick = (tab: Tab) => {
    if(disabled) return
    onSelectTab(tab)
    setActiveTab(tab)
  }
  */
  return (
    <div role="tablist" className={"neo__nav__container"}>
      <div className="neo__nav__wrapper">
        {tabs.map(tab => 
          <TabComponent
            tab={tab}
            isActive={!disabled && tab === activeTab}
            onClick={activeTab ? () => onSelectTab(tab) : null}
            disabled={disabled}
          />
        )}
      </div>
    </div>
  )
})

export default NavBar
