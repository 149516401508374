import React, { useEffect, useState } from "react"

import _ from "lodash"
import { MockAppContext } from "@root/src/context"
import { Block, Note, SectionTitle } from "@root/pages/sandbox"
import { AVATAR_ICONS, AvatarImageView, BuddyAddIcon, BuddyAvatar, BuddyAvatarPlaceholder, BuddySurround } from "."

const Sandbox:React.FC = () => {
  return <>
    <SectionTitle>Buddy</SectionTitle>
    <Note>
      Buddy, with new story, loading placeholder, add icon
    </Note>
    <Block>
      <BuddySurround type="transparent"><BuddyAvatar user={{id: 99999999, label: "Sonic", image: {type: "icon", arg: AVATAR_ICONS[0]}}} /></BuddySurround>
      <BuddySurround type="newStory" onClick={() => console.log("click")}><BuddyAvatar user={{id: 99999999, label: "Sonic"}} /></BuddySurround>
      <BuddySurround type="nothingNew"><BuddyAvatarPlaceholder /></BuddySurround>
      <BuddySurround type="transparent" onClick={() => console.log("click")}><BuddyAddIcon /></BuddySurround>
    </Block>
    <Note>AvatarImages</Note>
    <Block>
      {_.map(AVATAR_ICONS, (a) => {
        return <AvatarImageView key={a} image={{type: "icon", arg: a}} />
      })}
    </Block>
  </>
}

export default ["ui", Sandbox]
