import React from "react"
import { Block, Note, SectionTitle } from "@root/pages/sandbox"
import { Row } from "../Ui"
import { TinyPagination } from "."

const Sandbox:React.FC = () => {
  return <>
    <SectionTitle>TinyPagination</SectionTitle>
    <Note>
      first, somewhere, last, just one
    </Note>
    <Block>
      <Row>
        <TinyPagination page={1} total_pages={10} toPage={console.log} />
      </Row>
      <Row>
        <TinyPagination page={5} total_pages={10} toPage={console.log} />
      </Row>
      <Row>
        <TinyPagination page={10} total_pages={10} toPage={console.log} />
      </Row>
      <Row>
        <TinyPagination page={1} total_pages={1} toPage={console.log} />
      </Row>
      <Row>
        <TinyPagination page={1} total_pages={0} toPage={console.log} />
      </Row>
    </Block>
  </>
}

export default ["ui", Sandbox]
