import React, { useEffect, useState } from "react"

import _ from "lodash"
import { MockAppContext } from "@root/src/context"
import { Block, Note, SectionTitle } from "@root/pages/sandbox"
import { BetPoints } from "."

const Sandbox:React.FC = () => {
  return <>
    <SectionTitle>BetPoints</SectionTitle>
    <Note>
      None, some, correct, wrong
    </Note>
    <Block>
      <BetPoints variant="default" />
      <BetPoints points={20} variant="default" />
      <BetPoints points={20} variant="correct" />
      <BetPoints points={0} variant="wrong" />
    </Block>
  </>
}

export default ["ui", Sandbox]
