import React, { useState, PropsWithChildren, useRef, useEffect } from 'react'
import _ from 'lodash'
import WithTheme, { Theme } from '@root/src/helper/WithTheme'
import { initI18n } from '@root/src/MountApp'

export interface SandboxEntry {
  name: string
  Comp: (() => JSX.Element)[]
}

type NeoFC<Props = {}> = React.FC<React.PropsWithChildren<Props>>

const SectionTitle: NeoFC = ({ children }) => { return <h2 className={'sandbox__sectionTitle'}><span>{children}</span></h2> }
const BlockTitle: NeoFC<{info?: any}> = ({ children, info }) => { return <h2 className={'sandbox__blockTitle'} title={info || null}>{children}</h2> }
const Block: NeoFC<{slim?: boolean, framed?: boolean}> = ({ children, slim, framed }) => {
  const className = [
    "sandbox__block",
    slim ? "sandbox__block--slim" : "",
    framed ? "sandbox__block--framed" : ""
  ].join(" ")
  return <div className={className}>{children}</div>
}
const Note: NeoFC = ({ children }) => { return <div className={'sandbox__note'}>{children}</div> }
const Todo: NeoFC = ({ children }) => { return <div className={'sandbox__todo'}>{children}</div> }
const Grid: React.FC<{items: React.ReactNode[], columnsPhone?: number, columnsDesktop?: number}> = ({ items, columnsPhone = 2, columnsDesktop = 3 }) => {
  const className = [
    "sandbox__grid",
    `sandbox__grid--desktop${columnsDesktop}`,
    `sandbox__grid--phone${columnsPhone}`
  ].join(" ")

  return <div className={className}>
    {_.map(items, (item, index) => {
      return <div key={index}>{item}</div>
    })}
  </div>
}

const PageTitle: NeoFC = ({ children }) => {
  return (
      <h1 className='sandbox__pageTitle'>
        {children}
      </h1>
  )
}

const prefillActiveSandboxIndexFromURL = () => {
  const useStringBehind = "sandbox#"
  if (window.location.href.indexOf(useStringBehind) > -1) {
    try {
      const index = parseInt(window.location.href.split(useStringBehind)[1])
      if(_.isNumber(index) && index > -1) { return index; }
    } catch {
    }
  }
  return -1
}

const themeIcon = (theme: Theme) => {return theme === 'dark' ? "🌙" : "🌞"}

export const collectSandboxFilesFromContext = (context: any, namePrefix = ""): SandboxEntry[] => {
  let _sandboxes: SandboxEntry[] = []
  context.keys().forEach((key: string) => {
    if (key.indexOf("./") !== 0) { return } // just collect modules in subdirectories, starting with `./`
    let name: string, Comp: any
    let exported = context(key).default
    if (_.isArray(exported)) { // add to existing sandbox page
      name = exported[0]
      Comp = exported[1]
    } else {
      name = key.split('/')[key.split('/').length - 1].split('.sandbox.ts')[0].split('.sandbox.tsx')[0]
      Comp = exported
    }
    name = namePrefix + name
    if (!_.isArray(Comp)) { Comp = [Comp] } // normalize Comp to array

    let sandboxEntry = _.find(_sandboxes, { name: name })
    if (!sandboxEntry) {
      const initialComps = _.isArray(exported) ? [ () => { return <PageTitle>{_.capitalize(name)}</PageTitle> } ] : []
      sandboxEntry = { name: name, Comp: initialComps }
      _sandboxes.push(sandboxEntry as SandboxEntry)
    }
    sandboxEntry.Comp = sandboxEntry.Comp.concat(Comp)
  })
  return _.sortBy(_sandboxes, (i) => i.name.toLocaleLowerCase())
}

// Pack all sharedSandbox files
const sharedSandboxFilesContext = require.context('../', true, /\.sandbox\.(ts|tsx)$/)
sharedSandboxFilesContext.keys().forEach((key) => { sharedSandboxFilesContext(key) })

const Sandbox: React.FC<{init: {locale: string}}> = ({init}) => {
  initI18n(init.locale)

  const sandboxesRef = useRef<SandboxEntry[]>()
  const [theme, setTheme] = useState<Theme>("light")
  if (!sandboxesRef.current) {
    sandboxesRef.current = collectSandboxFilesFromContext(sharedSandboxFilesContext)
  }
  const sandboxes = sandboxesRef.current

  const [ state, __setState ] = useState<{activeSandboxIndex: number, uniqueKey: number}>({
    activeSandboxIndex: prefillActiveSandboxIndexFromURL(),
    uniqueKey: 0
  });

  const setActiveSandboxIndex = (index: number) => {
    __setState((prev) => {
      return {
        activeSandboxIndex: index,
        uniqueKey: prev.uniqueKey + 1
      }
    })
  }

  const SandboxComps = state.activeSandboxIndex > -1
      ? sandboxes[state.activeSandboxIndex]?.Comp || null
      : null

  return (
      <div className="sandbox__fullPageWrapper">        
        <div className="sandbox__navi">
          <a style={{position: "fixed", top: 0, left: 0, zIndex: 9999}}onClick={() => setTheme((prev) => prev === "dark" ? "light" : "dark")}>{themeIcon(theme)}</a>
          <a href="/" className="sandbox__navi__link">⌂</a>
          {_.map(sandboxes, ({ name, Comp }, index) => {
            const className = [
              "sandbox__navi__link",
              index === state.activeSandboxIndex ? "--active" : ""
            ].join(" ")
            return (
                <a href={`#${index}`} className={className} onClick={() => setActiveSandboxIndex(index)} key={name}>{(name[0] || "").toUpperCase() + name.slice(1)}</a>
            )
          })}
        </div>
        <WithTheme theme={theme} withBackground>
          <div className="sandbox__pageContent">
            {SandboxComps && _.map((SandboxComps), (C, index) => { return <C key={`${state.uniqueKey}_${index}`} /> })}
          </div>
        </WithTheme>
      </div>
  )
}

export { SectionTitle, PageTitle, BlockTitle, Block, Note, Todo, Grid }

export default Sandbox
