import React, { useState } from "react"

import { PageTitle, SectionTitle, Block, Note } from '@root/pages/sandbox'
import _ from "lodash"
import CompleteProfileView from "./CompleteProfileView"
import { INIT_STATE } from "../state"
import { MockAppContext } from "../context"

const Sandbox:React.FC = () => {
  const [withError, setWithError] = useState(false)

  return <>
    <Note><a onClick={() => setWithError(prev => !prev)}>Toggle error</a></Note>
    <Block>      
      <MockAppContext state={{...INIT_STATE, me: {initialized: true, user: {id: 99999999, label: "Sonic"}},
        completeProfileView: {
          error: withError ? "Nickname must be unique!" : undefined
        }}}>
        <CompleteProfileView />
      </MockAppContext>
    </Block>
  </>
}

export default ["#/complete_profile", Sandbox]
