import React from "react"

import {PageTitle, SectionTitle, Block, Note} from "../../../pages/sandbox"
import _ from "lodash"
import {Row} from "../Ui"
import RankingView from "."
import {MockAppContext} from "@root/src/context"
import {RankingEntry} from "@root/types"
import {INIT_STATE} from "@root/src/state"

const ENTRIES: RankingEntry[] = [
	{
		user: {id: 1, label: "Alice asdfasdfsadf loremsadf asdjfaisdhfalkjsdhfaljsjdgfjahsdhgfjhaksdgfljhahsgdfjasdhg", is_expert: true},
		rank: 1,
		score: 95,
	},
	{
		user: {id: 2, label: "Bob"},
		rank: 2,
		score: 90,
	},
	{
		user: {id: 3, label: "Charlie", is_expert: false},
		rank: 3,
		score: 85,
	},
]
const Sandbox: React.FC = () => {
	return (
		<>
			<Block>
				<Note>First time loading</Note>
				<Row>
					<RankingView />
				</Row>
			</Block>
			<Block>
				<Note>Loaded</Note>
				<Row>
					<MockAppContext
						state={{
							...INIT_STATE,
							me: {
								initialized: true,
								user: {id: 2, label: "Bob"},
							},
							rankingView: {
								config: {
									ranking: {
										page: 2,
										total_pages: 5,
										entries: ENTRIES,
										ranking_type: "1",
										filter: "buddies",
									},
									ranking_types: [{name: "1", id: "1"},{name: "global", id: ""}],
								},
							},
							matchdayView: {
								config: {
									matchday: {
										id: 5,
										title: "",
										subtitle: "",
										state: "open",
										bets: []
									}
								}
							}
						}}
					>
						<RankingView />
					</MockAppContext>
				</Row>
			</Block>
			<Block>
				<Note>Loaded, empty</Note>
				<Row>
					<MockAppContext
						state={{
							...INIT_STATE,
							me: {
								initialized: true,
								user: {id: 2, label: "Bob"},
							},
							rankingView: {
								config: {
									ranking: {
										page: 2,
										total_pages: 5,
										entries: [],
										ranking_type: "1",
										filter: "experts",
									},
									ranking_types: [{name: "1", id: "1"},{name: "global", id: ""}],
								},
							},
							matchdayView: {
								config: {
									matchday: {
										id: 5,
										title: "",
										subtitle: "",
										state: "open",
										bets: []
									}
								}
							}
						}}
					>
						<RankingView />
					</MockAppContext>
				</Row>
			</Block>
		</>
	)
}

export default Sandbox
