import React, { useEffect, useState } from "react"

import _ from "lodash"
import { MockAppContext } from "@root/src/context"
import DisplayErrorOverlay from "../DisplayErrorOverlay"
import { INIT_STATE } from "@root/src/state"
import { Block, Note, SectionTitle } from "@root/pages/sandbox"

const Sandbox:React.FC = () => {
  const [overlay, setOverlay] = useState(false)

  useEffect(() => {
    let timeout: number
    if (overlay) {
      timeout = window.setTimeout(() => setOverlay(false), 3000)
    }
    return () => { // cleanup
      if (_.isNumber(timeout)) { window.clearTimeout(timeout)}
    }
  }, [overlay])

  return <>
    <SectionTitle>Display Error Overlay</SectionTitle>
    <Note>
      <ul>
        <li><a onClick={() => setOverlay(true)}>Show error popup for 3 sec</a></li>
      </ul>
    </Note>
    <Block>
      <MockAppContext state={{...INIT_STATE,
        error: overlay ? {error: "this is an error message!"} : undefined,
        }}>
        <>
          <DisplayErrorOverlay />
        </>
      </MockAppContext>
    </Block>
  </>
}

export default ["ui", Sandbox]
